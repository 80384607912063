import React, { useRef, useEffect, useState } from "react";
import { useAuth0 } from "../../auth/Auth";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MenuProfile from "../../components/header/MenuProfile_Synth";
import acmesynthesizerimage from "../../assets/images/synthesizer/acmeSynthBlue.png"
import { getStories } from "../../api/synth/getstories"
import Spinner from "../../components/layouts/Spinner/Spinner";
import { SideBar } from '../../components/SynthHeader/SideBar'
import DeleteModal from '../../components/synth/DeleteModal';
import { deleteProject } from "../../api/synth/deleteproject";


const SynthProjects = () => {
  const [isLoading, setLoading] = useState(false);
  const [projects, setProjects] = React.useState([]);
  const [isModalOpen, setModalOpen] = React.useState(false);
  const [modalPosition, setModalPosition] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);


  const { isAuthenticated } = useAuth0();
  const headerRef = useRef(null);
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  React.useEffect(() => {
    setLoading(true);
    const fetchProjects = async () => {
      try {
        const response = await getStories();
        const sortedProjects = response.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
      setProjects(sortedProjects);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProjects();
  }, []); // Empty dependency array means this effect runs once on mount

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = React.useState("");
  
  const toggleModal = () => {
    setModalOpen(!isModalOpen);
};

const Modal = ({ isOpen, onClose, onAction, children, position }) => {
  if (!isOpen || !position) return null;

  const style = {
    top: position.y,
    left: position.x,
  };

  return (
    <PopoverContainer style={style} onClick={e => e.stopPropagation()}>
      {children}
      <DeleteText onClick={onAction}>Delete</DeleteText>
    </PopoverContainer>
   );
  };

  const handleOptionsClick = (project, e) => {
    e.stopPropagation();
    const buttonRect = e.target.getBoundingClientRect();
    setModalPosition({ x: buttonRect.right, y: buttonRect.top });
    setSelectedProjectId(project.id); // Set the selected project ID
    toggleModal();
  };
  
  const handleDeleteAction = (e) => {
    toggleModal();
    e.stopPropagation();
    setShowDeleteModal(true); // Open the DeleteModal
};


  const handleCreateProject = () => {
    navigate("/createstory");
  };

  const handleDeleteProject = async () => {
    console.log(`Delete Project: ${selectedProjectId}`);
    if (selectedProjectId) {
      await deleteProject(selectedProjectId);
      setShowDeleteModal(false);
      setSelectedProjectId(null); // Reset the selected project ID after deletion
      const response = await getStories();
        const sortedProjects = response.sort((a, b) => new Date(b.lastUpdated) - new Date(a.lastUpdated));
      setProjects(sortedProjects);
    }
  };


  /*const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };*/

  const handleProjectClick = (projectId, projectTitle) => {
    //console.log(`Clicked on project: ${projectTitle}`);
    navigate(`/editstory?projectName=${encodeURIComponent(projectTitle)}&storyId=${encodeURIComponent(projectId)}`)
  };

  const handleFavoriteClick = (project) => {
    console.log(`Favorited project: ${project.title}`);
  };

 const filteredProjects = projects.filter((project) =>
    project.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const defaultImage = 'https://develop-cover-images.s3.amazonaws.com/retorbot.png';
  
  const isSticky = (e) => {
    const header = document.querySelector(".js-header");
    if (header) {
      const scrollTop = window.scrollY;
      scrollTop >= 300
        ? header.classList.add("is-fixed")
        : header.classList.remove("is-fixed");
      scrollTop >= 400
        ? header.classList.add("is-small")
        : header.classList.remove("is-small");
    }
  };

  return (
    <AppContainer>
      <AppLayout>
      <Modal 
          isOpen={isModalOpen} 
          onClose={toggleModal} 
          onAction={handleDeleteAction} 
          position={modalPosition}
        >
        </Modal>
        <MainContent>
          <Header>
          {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>Getting Your Stories....
            </ProgressStatus>
            <Spinner />
          </ProgressOverlay>
          )}
            <AppTitle>
            <img
              src={acmesynthesizerimage}
              alt="ACME Synthesizer"
              />
              </AppTitle>
            <CreateProjectButton onClick={handleCreateProject}>
              <PlusIcon>+</PlusIcon>
              <CreateProjectText>Create New Project</CreateProjectText>
            </CreateProjectButton>
          </Header>
          <Divider />
          
          <RecentProjectsHeader>
            <RecentProjectsTitle>Recent Projects</RecentProjectsTitle>
            {/*<SearchBar>
              <SearchText>Search </SearchText>
              <SearchIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/b4ee190e27c4c8ae6c228d09764fed375140fe13908fde5038f3abe1b34f6b80?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Search Icon" />
            </SearchBar>*/}
          </RecentProjectsHeader>
          <ProjectsView>
            {!projects ? (
              <NoProjects>An Error has Occurred</NoProjects>
            ) : (projects.length === 0 && isLoading === false) ? (
              <NoProjects>No Projects</NoProjects>
            ) : (
              projects.map((project, index) => (
                <ProjectCard 
                  key={project.id}
                  onClick={() => handleProjectClick(project.id, project.title)}
                >
                  <ProjectHeader>
                    <ProjectDetails>
                      <ProjectTitle>{project.title}</ProjectTitle>
                      <ProjectLastOpened>Last Updated: {project.lastUpdated}</ProjectLastOpened>
                    </ProjectDetails>
                    <ProjectActions>
                    <IconWrapper>
                        <FavoriteIcon src="https://al-thumbnails-prod.s3.amazonaws.com/Favorite_unchecked.svg" alt="Favorite" />
                        <OptionsIcon 
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/abcaba43fb588f21b92be64708d231676849bfe1142a85e071d30b3eff0fe3bd?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&"
                          alt="Options"
                          onClick={(e) => handleOptionsClick(project, e)}
                          />
                    </IconWrapper>
                    </ProjectActions>
                  </ProjectHeader>
                  <ProjectImage src={project.storyImage || defaultImage} alt={`${project.title} Project`} />
                </ProjectCard>
              ))
            )}
              {showDeleteModal && (
                <DeleteModal 
                isOpen={!!selectedProjectId} 
                onClose={() => setShowDeleteModal(false)}
                onCancel={() => setShowDeleteModal(false)}
                onDelete={handleDeleteProject}
              />
            )}
          </ProjectsView>
        </MainContent>
        <Sidebar>
          <SidebarContent>
            <UserInfo>
              <UserDetails>
                {/*<UserAvatar />*/}
                <Username>{!isAuthenticated ? null : <MenuProfile />}</Username>
              </UserDetails>
              {/*<SettingsIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f4da968377af5bd1d41492bd3003ee1cf0cc89b9f4ffde48b16ae2ec87a42f4?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Settings Icon" />*/}
            </UserInfo>
            <NavItem active>
              <NavIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/b116897c76a2638a5fe16d4da4245fa4bab73f29be83643d1c5a0af8367cfff7?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Recents Icon" />
              <NavText>Recents</NavText>
            </NavItem>
            {/*<NavItem>
              <NavIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/60209d339e815506f371a5cb82c458e634545ae3ab0ded653cd10ba527cc6adb?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Favorites Icon" />
              <NavText>Favorites</NavText>
              </NavItem>*/}
          </SidebarContent>
        </Sidebar>
      </AppLayout>
    </AppContainer>
  );
}


const AppContainer = styled.div`
  background-color: #fbfdff;
  font-family: Inter, sans-serif;
`;

const AppLayout = styled.div`
  display: flex;
  gap: 20px;
`;

const MainContent = styled.main`
  flex: 1;
  margin-left: 0;
`;

const ProjectsView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  
  gap: 20px; 
`;

const Header = styled.header`
  display: flex;
  margin-top: 26px;
`;

const AppTitle = styled.h1`
  color: #2159a2;
  flex: 1;
  font-size: 32px;
  font-weight: 700;
  letter-spacing: 0.1px;
  margin-left: 35px;
`;

const NoProjects = styled.h2`
  flex: 1;
  color: #2159a2;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  margin-left: 35px;
  padding-top: 10%;
  wordWrap: break-word;
  `;
const CreateProjectButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12 20px;
  width: 257px;
  height: 36px;
  border: 1px solid #d9d9d9;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 4px 10.8px 0 rgba(0, 0, 0, 0.25);
  font-weight: 600;
`;

const PlusIcon = styled.span`
  font-size: 32px;
  color: #2159a2;
`;

const CreateProjectText = styled.div`
  font-size: 16px;
  color: #2159a2;
`;

const Divider = styled.hr`
  height: 1px;
  margin-top: 23px;
  border: none;
  background-color: #d9d9d9;
`;

const RecentProjectsHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 25px 30px 0 0;
`;

const RecentProjectsTitle = styled.h2`
  flex: 1;
  color: #011126;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
  color: #2159a2;
`;

const SearchBar = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 150px;
  padding: 5px 50px;
  border: 1px solid #81818a;
  border-radius: 10px;
  background-color: #fff;
  color: #807f7f;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.5px;
  line-height: 24px;
  width: 257px;
  height: 36px;
`;

const SearchText = styled.span`
  font-family: Inter, sans-serif;
`;

const SearchIcon = styled.img`
  width: 24px;
  aspect-ratio: 1;
  object-fit: contain;
`;

const ProjectCard = styled.article`
  display: flex;
  flex-direction: column;
  width: 325px;
  max-width: 100%;
  margin: 30px 0 0 20px;
  padding: 29px 9px 14px;
  border: 1px solid #d9d9d9;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 3px 4px 6px 0 rgba(0, 0, 0, 0.25);
`;

const ProjectHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  gap: 20px;
`;

const ProjectDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const ProjectTitle = styled.h3`
  color: #2159a2;
  font-size: 24px;
  font-weight: 700;
`;

const ProjectLastOpened = styled.p`
  color: #81818a;
  font-size: 12px;
  font-weight: 400;
`;

const ProjectActions = styled.div`
  display: flex;
  gap: 9px;
`;

const ProjectImage = styled.img`
  width: 100%;
  margin-top: 21px;
  aspect-ratio: 1.27;
  object-fit: contain;
`;

const Sidebar = styled.aside`
  width: 20%;
  margin-left: 20px;
`;

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;
  background-color: #f5f5f7;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 28px 22px;
  border: 1px solid #d9d9d9;
  background-color: #fbfdff;
  color: #011126;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 100%;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const UserAvatar = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #d9d9d9;
`;

const Username = styled.span`
  font-family: Inter, sans-serif;
`;

const SettingsIcon = styled.img`
  width: 15px;
  aspect-ratio: 1.67;
  object-fit: contain;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: ${(props) => (props.active ? "12px 21px" : "0 21px")};
  margin: ${(props) => (props.active ? "0" : "13px 0 0 0")};
  background-color: ${(props) => (props.active ? "#2159a2" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
`;

const NavIcon = styled.img`
  width: ${(props) => (props.active ? "22px" : "25px")};
  aspect-ratio: ${(props) => (props.active ? "1" : "1.04")};
  object-fit: contain;
  align-self: ${(props) => (props.active ? "flex-start" : "center")};
`;

const NavText = styled.span`
  font-family: Inter, sans-serif;
  margin: auto 0;
`;
const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;
const IconWrapper = styled.div`
  display: flex;
  gap: 9px;
`;

const FavoriteIcon = styled.img`
  aspect-ratio: 1.05;
  object-fit: contain;
  width: 21px;
`;

const OptionsIcon = styled.img`
  aspect-ratio: 0.32;
  object-fit: contain;
  width: 6px;
  align-self: start;
`;

const PopoverContainer = styled.div`
  position: absolute;
  width: 203px;
  height: 34px;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #D9D9D9;
  background: #FBFDFF;
  box-shadow: 3px 4px 7.8px 0px rgba(0, 0, 0, 0.25);
  margin-top: -5px;
    z-index: 100; // Ensure it's above other content
`;

const DeleteText = styled.div`
  color: #2159a2;
  font-family: Inter, sans-serif;
  font-size: 12px;
  font-weight: 500;
  font-weight: bold;
  line-height: normal;
  display: flex; // Use flexbox for alignment
  align-items: center; // Vertically center the text
  padding-left: 30px; // Add padding to the left
  height: 100%; // Take full height to align text vertically
  cursor: pointer; // Add this to show it's clickable
  background-color: #FBFDFF; // Default background color

  &:hover {
    background-color: #CCEDFF; // Change to desired hover color
  }
`;



export default SynthProjects;