import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useSearchParams } from "react-router-dom";
import facebookIcon from "../../assets/images/svg/facebook_icon.svg";
import googleIcon from "../../assets/images/svg/google_icon.svg";
import LinkWithQueryParams from "../../components/LinkWithQueryParams";
import Layout from "../../components/layouts/Layout";
import Spinner from "../../components/layouts/Spinner/Spinner";
import {
  AFFILIATE_QUERY_STRING,
  AUTH0_MANY_ATTEMPTS_SOLUTION,
  FORGOT_PASSWORD,
  IS_SOCIAL_ENABLED,
  LOGIN_ERROR_MESSAGE,
  SIGNUP_ERRORS_PRESENT,
} from "../../constants/constants";
import {
  auth0FacebookSignup,
  auth0GoogleSignup,
} from "../../helpers/auth0Service";
import LoginForm from "./LoginForm";
import { LoginPage } from "./styles";

const Login = () => {
  const [loading, setLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [tooManyError, setTooManyError] = useState(false);
  const { state } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  console.log("Opening LoginForm");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const afflCode = state?.search?.[AFFILIATE_QUERY_STRING];
    if (afflCode) {
      setSearchParams({ [AFFILIATE_QUERY_STRING]: afflCode }, { state });
    }
  }, []);

  return (
    <LoginPage>
      <Layout>
        <div id="spacer"></div>

        <div>
          <section className="tf-login tf-section">
            <div className="row m-0" style={{ position: "relative" }}>
              {loading && (
                <div className="spinner-overlay">
                  <Spinner />
                </div>
              )}

              <div className="col-md-6 col-12 left-background">
                <div className="overlay"></div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="page-title-heading mg-bt-12">
                      <div className="vertical-line"></div>
                      <h1 className="heading text-center">Welcome Back</h1>
                    </div>

                    <div className="header-container">
                      <h4>Log into your account</h4>
                    </div>
                    <div className="header-container style2 mb-4">
                      <h4>New to Acme Labs™?</h4>
                    </div>

                    <div className="journey-btn-link">
                      <LinkWithQueryParams path="/sign-up" className="m-3">
                        <span>Start Your Journey Here</span>
                      </LinkWithQueryParams>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6 col-12 right-container">
                {Object.entries(errors).length > 0 && (
                  <div className="general-error-container">
                    <p className="error-message text-center">
                      {SIGNUP_ERRORS_PRESENT}
                    </p>
                  </div>
                )}

                {IS_SOCIAL_ENABLED && (
                  <>
                    <div className="row m-0">
                      <div className="row justify-content-center m-0 mb-4 w-100">
                        <button
                          className="social-button"
                          onClick={() => auth0FacebookSignup()}
                        >
                          <img
                            className="facebookIcon mr-4"
                            src={facebookIcon}
                            alt="/"
                          />
                          Continue with Facebook
                        </button>
                      </div>
                      <div className="row justify-content-center m-0 mb-4 w-100">
                        <button
                          className="social-button"
                          onClick={() => auth0GoogleSignup()}
                        >
                          <img
                            className="googleIcon mr-4"
                            src={googleIcon}
                            alt="/"
                          />
                          Continue with Google
                        </button>
                      </div>
                    </div>

                    <div className="row align-items-center justify-content-center m-0 mb-4 w-100">
                      <div className="horizontal-line"></div>
                      <p className="centered-text">or</p>
                      <div className="horizontal-line"></div>
                    </div>
                  </>
                )}

                <LoginForm
                  register={register}
                  handleSubmit={handleSubmit}
                  errors={errors}
                  setLoading={setLoading}
                  setLoginError={setLoginError}
                  setTooManyError={setTooManyError}
                />

                <div
                  id="incorrect-credentials"
                  className="general-error-container"
                >
                  {loginError && (
                    <p className="error-message text-center">
                      {LOGIN_ERROR_MESSAGE}
                      <LinkWithQueryParams
                        path="/forgot-password"
                        className="forgot-pass"
                      >
                        {FORGOT_PASSWORD}
                      </LinkWithQueryParams>
                    </p>
                  )}
                  {tooManyError && (
                    <p className="error-message text-center">
                      {AUTH0_MANY_ATTEMPTS_SOLUTION}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </LoginPage>
  );
};

export default Login;
