import * as React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import { getCharactersById } from "../../api/synth/getCharacters";
import { deleteCharacter} from "../../api/synth/deleteCharacter"

const characterData = {
  name: "Sam Jones",
  description:
    "Tall Slim Cowboy. Early 20's, Red Shirt, Hand Drawn, Classic Animation Style",
  otherDetails:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
};

const styleOptions = [
  { label: "Example Dropdown", value: "example1" },
  { label: "Example Dropdown", value: "example2" },
  { label: "Example Dropdown", value: "example3" },
];

function CharacterEditPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [isLoading, setIsLoading] = React.useState(false);
  const [selectedStyle, setSelectedStyle] = React.useState(styleOptions[0].value);
  const [userInput, setUserInput] = React.useState("");
  const [character, setCharacter] = React.useState([]);

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');
  const characterId = queryParams.get('charId');

  React.useEffect(() => {
    // setLoading(true);
    const retrieveCharacter = async () => {
      setIsLoading(true);  
      try {
            const response = await getCharactersById(characterId);
            console.log('Character response:', response);
            setCharacter(response);
        } catch (error) {
            console.error(error);
            //notifyError("Failed to get character");
        } finally {
            setIsLoading(false);
        }
    };
    retrieveCharacter();
}, [characterId]); // Add storyId as a dependency to refetch when it changes

const handleStyleChange = (value) => {
    setSelectedStyle(value);
  };

  const handleUserInput = (event) => {
    setUserInput(event.target.value);
  };

  const handleSubmit = () => {
    console.log("Submit clicked with user input:", userInput);
    // Perform submit action with user input
  };

  const handleBackToCharacters = () => {
    console.log("Back to Characters clicked");
    navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  };

  const handleDeleteClick = async () => {
    console.log("Delete icon clicked");
    const id = characterId;
    const response = await deleteCharacter(id);
    navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    return;
    // Navigate to Delete page
  };

  return (
    <AppWrapper>
      <SynthHeader project={projectName} />
    <PageWrapper>
      <ContentWrapper>
        <ContentContainer>
          <MainContent>
            <CharacterDetails>
              <CharacterImage>
                <img src={character.charImage} alt="Character" />
              </CharacterImage>
              <CharacterInfo>
                <CharacterName>Edit Character</CharacterName>
                <CharacterNameLabel>Name:</CharacterNameLabel>
                <CharacterNameValue>{character.charName}</CharacterNameValue>
                <CharacterDescriptionLabel>
                  Character Description:
                </CharacterDescriptionLabel>
                <CharacterDescriptionValue>
                  {character.charDesc}
                </CharacterDescriptionValue>
                <OtherDetailsLabel>Other Details</OtherDetailsLabel>
                <OtherDetailsValue>{character.charOther}</OtherDetailsValue>
                <CharacterModelContainer>
                  <CharacterModelLabel>Character Model</CharacterModelLabel>
                  {/*<img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e2f2124071295e4272351a520e8c2f1f6841c334b685dd4b177a9f1446cd0f62?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Character Model" />*/}
                </CharacterModelContainer>
                <CharacterModel>
                    <img src={character.charModel} alt="Character" />
                </CharacterModel>
              </CharacterInfo>
            </CharacterDetails>
          </MainContent>
          <Sidebar>
            <SidebarContent>
              <SidebarTitle>Style</SidebarTitle>
              {styleOptions.map((option) => (
                <DropdownButton
                  key={option.value}
                  onClick={() => handleStyleChange(option.value)}
                  selected={selectedStyle === option.value}
                >
                  <DropdownLabel>{option.label}</DropdownLabel>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
                </DropdownButton>
              ))}
              <SectionTitle>Section Title</SectionTitle>
              <DropdownButton>
                <DropdownLabel>Example Dropdown</DropdownLabel>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
              </DropdownButton>
              <SectionTitle>Section Title</SectionTitle>
              <DropdownButton>
                <DropdownLabel>Example Dropdown</DropdownLabel>
                <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown Arrow" />
              </DropdownButton>
              <AIHelpText>
                Use AI to help you with your character.
                <br />
              </AIHelpText>
              <AIHelpSubtext>
                Say what you want to change in your story, and AI will implement
                those changes.{" "}
              </AIHelpSubtext>
              <UserInputField
                value={userInput}
                onChange={handleUserInput}
                placeholder="User inputs text here"
              />
              <SubmitButton onClick={handleSubmit}>Submit</SubmitButton>
              <NavigationContainer>
                <BackButton onClick={handleBackToCharacters}>
                  Back To Characters
                </BackButton>
                <DeleteIcon onClick={handleDeleteClick}>
                  <img src="https://cdn.builder.io/api/v1/image/assets/TEMP/62a1b46d-beb6-46cb-ae19-49eb5030fbe8?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Delete" />
                </DeleteIcon>
              </NavigationContainer>
            </SidebarContent>
          </Sidebar>
        </ContentContainer>
      </ContentWrapper>
    </PageWrapper>
    <Divider />
    <SynthFooter />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
`;

const PageWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentWrapper = styled.div`
  align-self: center;
  z-index: 10;
  margin-top: 6px;
  width: 100%;
  max-width: 1352px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ContentContainer = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 68%;
  margin-left: 0px;
  margin-top: 40px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CharacterDetails = styled.div`
display: flex; 
align-items: start;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const CharacterImage = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 30%;
  margin-left: 0px;
  object-fit: auto;
  @media (max-width: 991px) {
    width: 100%;
  }
  img {
    aspect-ratio: 0.31;
    object-fit: auto;
    object-position: center;
    width: 100%;
    margin-top: 5px;
    flex-grow: 1;
    @media (max-width: 991px) {
      margin-top: 40px;
    }
  }
`;

const CharacterModel = styled.div`
display: flex;
width: 245;
height: 213;
`;

const CharacterInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 70%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const CharacterName = styled.h1`
  color: #011126;
  text-align: center;
  align-self: start;
  margin-left: 33px;
  font: 700 40px Inter, sans-serif;
  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const CharacterNameLabel = styled.label`
font: 600 24px Inter, sans-serif;
  margin-top: 42px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 40px;
  }
`;

const CharacterNameValue = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  margin-top: 17px;
  align-items: start;
  justify-content: center;
  padding: 15px;
  font: 400 20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const CharacterDescriptionLabel = styled.label`
font: 600 24px Inter, sans-serif;
  margin-top: 32px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CharacterDescriptionValue = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  margin-top: 11px;
  align-items: start;
  padding: 14px 15px 52px;
  font: 400 20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const OtherDetailsLabel = styled.label`
font: 600 24px Inter, sans-serif;
  margin-top: 18px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const OtherDetailsValue = styled.div`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  margin-top: 16px;
  padding: 14px 15px 28px;
  font: 400 20px Inter, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const CharacterModelContainer = styled.div`
  align-self: start;
  display: flex;
  margin-top: 27px;
  gap: 14px;
`;

const CharacterModelLabel = styled.span`
font: 600 24px Inter, sans-serif;
  flex-grow: 1;
`;

const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 32%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const SidebarContent = styled.div`
  background-color: #f7f5f5;
  display: flex;
  width: 100%;
  flex-grow: 1;
  flex-direction: column;
  margin: 0 auto;
  padding: 24px 24px 80px;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding: 0 20px;
  }
`;

const SidebarTitle = styled.h2`
  color: #000;
  font: 600 18px Inter, sans-serif;
`;

const DropdownButton = styled.button`
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: ${(props) => (props.selected ? "#e0e0e0" : "#fff")};
  display: flex;
  margin-top: 9px;
  gap: 20px;
  font-size: 15px;
  color: #000;
  font-weight: 400;
  justify-content: space-between;
  padding: 10px 16px 10px 12px;
  cursor: pointer;
  img {
    aspect-ratio: 1.79;
    object-fit: auto;
    object-position: center;
    width: 16px;
    margin: auto 0;
  }
`;

const DropdownLabel = styled.span`
  font-family: Inter, sans-serif;
`;

const SectionTitle = styled.h3`
  color: #000;
  margin-top: 40px;
  font: 600 18px Inter, sans-serif;
`;

const AIHelpText = styled.p`
  color: #24777d;
  margin-top: 28px;
  font: 700 18px Inter, sans-serif;
`;

const AIHelpSubtext = styled.p`
  color: #011126;
  margin-top: 14px;
  font: 400 14px Inter, sans-serif;
`;

const UserInputField = styled.textarea`
  justify-content: flex-end;
  align-items: start;
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 15px;
  color: #81818a;
  padding: 29px 25px 170px;
  font: 400 15px Inter, sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px 40px;
  }
`;

const SubmitButton = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 19px;
  color: #fbfdff;
  white-space: nowrap;
  text-align: center;
  padding: 10px 45px;
  font: 600 16px Inter, sans-serif;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const NavigationContainer = styled.nav`
  display: flex;
  margin-top: 121px;
  gap: 20px;
  justify-content: space-between;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const BackButton = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  color: #2159a2;
  text-align: center;
  padding: 13px 29px;
  font: 600 18px Inter, sans-serif;
  cursor: pointer;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const DeleteIcon = styled.button`
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 3px 3px 9.2px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  display: flex;
  padding: 10px 20px;
  cursor: pointer;
  img {
    aspect-ratio: 0.85;
    object-fit: auto;
    object-position: center;
    width: 23px;
    fill: #2159a2;
  }
`;

export default CharacterEditPage;