import styled from "styled-components";
import { NAVBAR_TRANSITION_TIME } from "../../../constants/constants";
import { COMMON_STYLES } from "../../../constants/genericStyles";

export const StyledNavbar = styled.nav`
.mobile-nav {
  display: none;
}

#main-nav {
  // Default display setting for larger screens
  display: block;
}

@media only screen and (max-width: 768px) {
  #main-nav {
    // Hide main-nav on screens smaller than 768px
    display: none;
  }

  .mobile-nav {
    display: none; // This should be set to 'block' or 'flex' when active

    /* position related */
    position: absolute;
    top: 0;
    right: 0;
    left: auto;
    z-index: 777;

    /* content related */
    flex-direction: column;
    align-content: flex-start;
    align-items: center;
    flex-wrap: wrap;

    /* appearance related */
    width: 300px;
    min-height: 100vh; // Full viewport height
    background-color: #011126;
    border-bottom-left-radius: 13px;
    box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
  }

  .mobile-nav.active {
    display: flex;
  }

    .mobile-nav ul {
      width: 100%;
      margin-bottom: 31px;
    }

    .light-blue {
      background: #2159a2;
    }

    div.accordion-item {
      padding: 10px 29px;
    }

    .accordion span {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
    }

    .light-blue .menu-option {
      background: #2159a2;
    }

    .menu-option {
      width: 100%;
      margin-top: 10px;
    }

    .menu-option a,
    .menu-option p {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;

      color: #ffffff;
    }
    .menu-option p {
      color: #999;
    }

    .mobile-nav ul li {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      padding: 10px 29px;
    }
    #close {
      width: 100%;
      color: white;
      font-size: 40px;
      text-align: right;
      padding-right: 15px;
      margin-bottom: 15px;
    }
    #site-header {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    #sign-in {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px !important;
    }
    #signed-in {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px !important;
      padding: 0;
      padding-left: 29px;
      padding-right: 29px;
    }
    #signed-in .user-image {
      width: 20%;
    }
    #signed-in .user-image img {
      width: 100%;
      height: auto;
    }

    #signed-in .accordion {
      width: 75%;
    }

    #signed-in .user-info {
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
    }
    #signed-in .user-info .user-salutation {
      width: 100%;
    }
    #signed-in .user-info .user-logout {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: #ffffff;
      width: 100%;
    }
    #site-header .sc-button {
      background: #ff7b7c;
      border-radius: 13px;
      border: none;
      margin-bottom: 27px;
    }
    #site-header .sc-button span {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      padding: 10px 40px;
    }
  }
  .accordion__panel {
    animation: fadein 0.35s ease-in;
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* adding chevron icon */
  .accordion__button:after {
    display: inline-block;
    content: "";
    height: 8px;
    width: 8px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    transform: rotate(-135deg);
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  ${COMMON_STYLES.SLIDE_ANIMATION({
    transitionTime: NAVBAR_TRANSITION_TIME.toString(),
  })}

  ${COMMON_STYLES.FADE_IN}
`;



export const StyledNavbar_LP = styled.nav`
  .mobile-nav {
    display: none;
  }
  @media only screen and (max-width: 768px) {
    .mobile-nav {
      display: none;

      /* position related */
      position: absolute;
      top: 0;
      right: 0;
      left: auto;
      z-index: 777;

      /* content related */
      flex-direction: row;
      align-content: flex-start;
      align-items: center;
      flex-wrap: wrap;

      /* appearance related */
      width: 300px;
      min-height: 400px;
      height: auto;
      background-color: #011126;
      border-bottom-left-radius: 13px;
      box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
    }

    .mobile-nav.active {
      /* content related */
      display: flex;
    }

    .mobile-nav ul {
      width: 100%;
      margin-bottom: 31px;
    }

    .light-blue {
      background: #2159a2;
    }

    div.accordion-item {
      padding: 10px 29px;
    }

    .accordion span {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
    }

    .light-blue .menu-option {
      background: #2159a2;
    }

    .menu-option {
      width: 100%;
      margin-top: 10px;
    }

    .menu-option a,
    .menu-option p {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;

      color: #ffffff;
    }
    .menu-option p {
      color: #999;
    }

    .mobile-nav ul li {
      font-weight: 400;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      padding: 10px 29px;
    }
    #close {
      width: 100%;
      color: white;
      font-size: 40px;
      text-align: right;
      padding-right: 15px;
      margin-bottom: 15px;
    }
    #site-header {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
    #sign-in {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      margin-bottom: 20px !important;
    }
    #signed-in {
      width: 100%;
      margin: 0 !important;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 20px !important;
      padding: 0;
      padding-left: 29px;
      padding-right: 29px;
    }
    #signed-in .user-image {
      width: 20%;
    }
    #signed-in .user-image img {
      width: 100%;
      height: auto;
    }

    #signed-in .accordion {
      width: 75%;
    }

    #signed-in .user-info {
      width: 100%;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      color: #ffffff;
      display: flex;
      align-content: flex-end;
      flex-wrap: wrap;
    }
    #signed-in .user-info .user-salutation {
      width: 100%;
    }
    #signed-in .user-info .user-logout {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      text-decoration-line: underline;
      color: #ffffff;
      width: 100%;
    }
    #site-header .sc-button {
      background: #ff7b7c;
      border-radius: 13px;
      border: none;
      margin-bottom: 27px;
    }
    #site-header .sc-button span {
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      padding: 10px 40px;
    }
  }
  .accordion__panel {
    animation: fadein 0.35s ease-in;
  }

  .accordion__button {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* adding chevron icon */
  .accordion__button:after {
    display: inline-block;
    content: "";
    height: 8px;
    width: 8px;
    border-bottom: 2px solid white;
    border-right: 2px solid white;
    transform: rotate(45deg);
  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    transform: rotate(-135deg);
  }

  /* -------------------------------------------------- */
  /* ---------------- Animation part ------------------ */
  /* -------------------------------------------------- */
  ${COMMON_STYLES.SLIDE_ANIMATION({
    transitionTime: NAVBAR_TRANSITION_TIME.toString(),
  })}

  ${COMMON_STYLES.FADE_IN}
`;

