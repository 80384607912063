import { getHeaders } from "./utils";
const { REACT_APP_API_URL } = process.env;

const getStrParams = (params) => {
  let result = "?";
  return (
    result +
    Object.entries(params)
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value
            .map((item) => `${key}=${encodeURIComponent(item)}`)
            .join("&");
        } else {
          return `${key}=${encodeURIComponent(value)}`;
        }
      })
      .join("&")
  );
};

const filterVoidParams = (params) => {
  let newParams = {};
  for (let i in params) {
    params[i].length && (newParams[i] = params[i]);
  }
  return newParams;
};

const getItemsByUserId = async (userId, params) => {
  const newParams = filterVoidParams(params);
  let url = `${REACT_APP_API_URL}item/user/${userId}`;
  const fullpath = !Object.keys(newParams).length
    ? url
    : url + getStrParams(newParams);
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${fullpath}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export default getItemsByUserId;
