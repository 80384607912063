//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const getStories = async () => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/list`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getStoriesById = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/list/${id}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getScriptById = async (id) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/script/list/${id}`, {
      method: "GET",
      headers,
    });
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

