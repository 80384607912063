import React, { useEffect, useRef } from 'react';
import styled from "styled-components";
import Layout_Main from "../../components/layouts/Layout_Main"
import { useNavigate } from "react-router-dom";

const LandingPage = () => {
  const videoRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play()
        .catch(error => {
          console.error('Error attempting to play video:', error);
        });
    }
  }, []);

  const handleFeature1Click = () => {
    navigate(`/synthhome`);
  };

  const handleFeature2Click = () => {
    window.location.href = 'https://marketplace.acmelabs.xyz';
  };

  const handleUpcomingClick = () => {
    console.log("Upcoming button clicked");
  };

  const handleScrollClick = () => {
    window.scrollTo({
      top: 780, 
      left: 0,
      behavior: 'smooth'
    });
  };
  const handleScrollClick2 = () => {
    window.scrollTo({
      top: 1480, 
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleScrollClick3 = () => {
    window.scrollTo({
      top: 2220, 
      left: 0,
      behavior: 'smooth'
    });
  };

  const handleAboutUs = () => {
    console.log("About Us button clicked");
  };

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0, 
      left: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Layout_Main>
      <PageWrapper>
        <HeroSection>
        <HeroBackground src="https://alsynthprod.s3.amazonaws.com/spaceBackgroundx.png" alt="Hero background" loading="lazy" />
        <HeroContent>
          <HeroTextContent>
            <HeroSubtitle>Welcome to the future of</HeroSubtitle>
            <HeroLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0776a6c56e6ee35df7303f99f99706c5aa2ed9daafe15dfafe457a11ee2ce07?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Acme Labs logo" loading="lazy" />
            <HeroDescription>
              Acme Labs is pioneering ethical AI technologies to augment and accelerate the creative process across all multimedia formats. 
              <br/><br/>Our flagship products include the Acme Synthesizer™ AI-assisted creative suite, the Acme Atomizer™ for authenticating 
              human vs AI-generated IP, and certified distribution channels for AI-assisted creative works.
            </HeroDescription>
            <HeroScrollButton onClick={handleScrollClick}>
              <HeroScrollIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/95225c9cb1d94730448566ca796b2eba533b22f00a7f642d172724b0a0792307?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Scroll down icon" loading="lazy" />
            </HeroScrollButton>
          </HeroTextContent>
        </HeroContent>
        </HeroSection>
        <SynthContainer>
      <SynthContent>
      <SynthBackground
            ref={videoRef}
            src="https://alsynthprod.s3.amazonaws.com/Coffee.mp4"
            type="video/mp4"
            loop
            muted
            // autoplay is not needed since we're triggering play programmatically
          />
        <SynthText>
          <SynthSubtitle>Bring your animation to life with</SynthSubtitle>
          <SynthLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/c0bb1c222b7b4b75e4d1ee27e9371bfc2a6bead123eb47217785c60b9e715030?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Synth Logo" />
          <SynthDescription>
            This innovative product harnesses the power of cutting-edge AI
            technology to enable users to craft high-quality stories quickly
            and effortlessly. By integrating advanced language models and
            creative algorithms, it offers a seamless and intuitive interface
            that guides users through the process of developing compelling
            narratives tailored to their unique preferences and styles.
          </SynthDescription>
        </SynthText>
        <CtaSection>
          <CtaButton onClick={handleFeature1Click}>Open Experience</CtaButton>
          <CtaIcon onClick={handleScrollClick2}src="https://cdn.builder.io/api/v1/image/assets/TEMP/95225c9cb1d94730448566ca796b2eba533b22f00a7f642d172724b0a0792307?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="CTA Icon" />
        </CtaSection>
      </SynthContent>
    </SynthContainer>
      </PageWrapper>
        <MarketPlace>
         <MarketplaceContent>
          <MarketplaceTitle>Build your collection of digital artwork with</MarketplaceTitle>
          <MarketplaceLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/0caa3bd393f5f6b38e171e9d7a03abc99c9a457f9fac3bf0c418358d6a850a78?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="ACME Marketplace"/>
          <MarketplaceDescription>Buy, sell, and experience digital art and collectibles in virtual places and spaces. Join a community built around you.</MarketplaceDescription>
          <MarketplaceButton onClick={handleFeature2Click}>Open Experience</MarketplaceButton>
         </MarketplaceContent>
         <MarketplaceImages>
            <MPImage src="https://alsynthprod.s3.amazonaws.com/marketplace_image1.png" alt="Small" loading="lazy" />
            <MPImage src="https://alsynthprod.s3.amazonaws.com/marketplace_image2.png" alt="Small" loading="lazy" />
            <MPImage src="https://alsynthprod.s3.amazonaws.com/marketplace_image3.png" alt="Small" loading="lazy" />
         </MarketplaceImages>
         <SmallImage onClick={handleScrollClick3}src="https://cdn.builder.io/api/v1/image/assets/TEMP/95225c9cb1d94730448566ca796b2eba533b22f00a7f642d172724b0a0792307?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Small" loading="lazy" />
        </MarketPlace>
        <BottomSection>
          <BottomContent>
            <ComingSoonSection>
              <ComingSoonContent>
                <ComingSoonTitle>Coming Soon</ComingSoonTitle>
                <ComingSoonImage
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/4209f98291de88f78359ee494b9778784d5909b046a86829d855d81262d4d947?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&"
                  alt="Coming Soon"
                  loading="lazy"
                />
                <ComingSoonDescription>
                The upcoming ethical AI product offers a robust solution for creators to securely register their intellectual property, employing cutting-edge encryption and blockchain technology to ensure comprehensive protection.
                </ComingSoonDescription>
              </ComingSoonContent>
            </ComingSoonSection>
            <AboutUsSection>
              <AboutUsContent>
                <AboutUsTitle>Learn more about</AboutUsTitle>
                <AboutUsImage
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/e9beed30b16e3f8144a2b9092fc7061c56503d47aa312b93989d4849b66ba080?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&"
                  alt="About Us"
                  loading="lazy"
                />
                <AboutUsButton onClick={handleAboutUs}>
                About Us
                </AboutUsButton>
              </AboutUsContent>
            </AboutUsSection>
          </BottomContent>
        </BottomSection>
        <BottomImage onClick={handleScrollTop} src="https://cdn.builder.io/api/v1/image/assets/TEMP/eae16d8abdd44b3f76059c9d516bd0763f93ab17b5f046509fada630e45f2458?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Bottom" loading="lazy" />
    </Layout_Main>
  )
};

  const PageWrapper = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
`;
  const HeroSection = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 574px;
  width: 100%;
  padding: 20px 0;
  color: #fff;
  text-align: center;
  overflow: hidden;
`;

const HeroBackground = styled.img`
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const HeroContent = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 80px 60px 44px;


  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const HeroTextContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 800px;
  max-width: 100%;
  margin-top: 30px;
`;

const HeroSubtitle = styled.h2`
  align-self: center;
  font: 600 24px "Josefin Sans", sans-serif;
`;

const HeroLogo = styled.img`
  width: 100%;
  margin-top: 23px;
  aspect-ratio: 4.17;
  object-fit: contain;
  object-position: center;
`;

const HeroDescription = styled.div`
  margin-top: 35px;
  font: 400 20px "Josefin Sans", sans-serif; 
  font-size: 24px;
  letter-spacing: 2px;

  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const HeroScrollButton = styled.div`
  width: 45px;
  margin-top: 25px;
  aspect-ratio: 1.28;
  aspect-ratio: 1.28;
  object-fit: contain;
  object-position: center;
  align-self: center;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const HeroScrollIcon = styled.img`
  width: 45px;
  margin-top: 48px;
  aspect-ratio: 1.28;
  object-fit: contain;
  object-position: center;
  align-self: center;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const SynthContainer = styled.section`
  display: flex;
  width: 100%;
  flex-direction: column;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SynthContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  height: 700px;
  width: 100%;
  padding: 80px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SynthBackground = styled.video`
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`;

const SynthText = styled.div`
  z-index: 10;
  display: flex;
  width: 605px;
  max-width: 100%;
  flex-direction: column;
  color: #fff;
  margin-top: 114px;
  margin-right: 10%;
  align-self: flex-end; // Align this block to the right
  @media (max-width: 991px) {
    margin-top: 40px;
    margin-right: 0;
  }
`;

const SynthSubtitle = styled.h2`
  font: 600 24px Josefin Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const SynthLogo = styled.img`
  aspect-ratio: 12.5;
  object-fit: contain;
  object-position: center;
  width: 574px;
  margin-top: 17px;
  max-width: 100%;
`;

const SynthDescription = styled.div`
  margin-top: 16px;
  font: 400 20px Josefin Sans, sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const CtaSection = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 20px;
  color: #2159a2;
  font-weight: 600;
  text-align: center;
  width: 100%; // Or set a specific width if needed
  padding: 37px 80px 80px;
  }
`;

const CtaButton = styled.div`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  align-self: start;
  padding: 13px 41px;
  cursor: pointer;
  align-self: flex-end; // Align this block to the right
  margin-right: 20%;
  @media (max-width: 991px) {
    margin-left: 8px;
    padding: 0 20px;
  }
`;

const CtaIcon = styled.img`
  aspect-ratio: 1.28;
  object-fit: contain;
  object-position: center;
  width: 45px;
  align-self: center;
  margin: 96px 0 19px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const MarketPlace = styled.section`
  background-color: #011126;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto; // Ensure this container can grow with its content
  position: relative; // Needed for absolute positioning of its children
`;

const MarketplaceContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute; // Position it over the MarketplaceImages
  width: 100%;
  text-align: center;
  align-items: center; // Center the content horizontally
  z-index: 2; // Ensure it's above the images
`;

const MarketplaceTitle = styled.h2`
margin-top: 50px;
color: #fff;  
align-self: center;
`;

const MarketplaceLogo = styled.img`
  aspect-ratio: 14.29;
  object-fit: auto;
  object-position: center;
  width: 100%;
  max-width: 700px;
  align-self: center;
  margin-top: 22px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MarketplaceDescription = styled.p`
  color: #FFF;
  align-self: center;
  text-align: center;
  font-family: "Josefin Sans";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;  
  margin-top: 25px;
  max-width: 600px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const MarketplaceButton = styled.div`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0 rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  align-self: center;
  margin-top: 32px;
  color: #2159a2;
  padding: 13px 41px;
  cursor: pointer;
  font-size: 20px;
  font-weight: 700;

  @media (max-width: 991px) {
    padding: 13px 20px;
  }
`;

const MarketplaceImages = styled.div`
  margin-top: 200px;  
  display: flex; 
  justify-content: center; 
  flex-wrap: wrap; 
  gap: 10px;
  width: 100%;
  position: relative; // Ensure it's in the flow of the document
  z-index: 1; // Under the MarketplaceContent
`;


const MPImage = styled.img`
  object-fit: auto;
  margin-left: 50px;
  max-height: 425px;
`;

const SmallImage = styled.img`
  aspect-ratio: 1.28;
  object-fit: auto;
  object-position: center;
  width: 45px;
  margin-top: 40px;
  margin-bottom: 40px;
  align-self: center;
`;

const BottomSection = styled.section`
  align-self: center;
  margin-top: 30px;
  width: calc(100% - 60px); // Subtracting total left and right margins
  margin-left: 30px;
  margin-right: 30px;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
`;

const BottomContent = styled.div`
  display: flex;
  justify-content: center; // Horizontally center the child elements
  gap: 20px; // Gap between children
  width: 100%;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ComingSoonSection = styled.section`
  flex-basis: calc(50% - 10px); // Adjust for gap
  display: flex;
  flex-direction: column;
  line-height: normal;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const ComingSoonContent = styled.div`
  border-radius: 10px;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 80px 42px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 23px;
    padding: 80px 20px;
  }
`;

const ComingSoonTitle = styled.h2`
  font-family: Josefin Sans, sans-serif;
  font-style: italic;
  margin-top: 33px;
`;

const ComingSoonImage = styled.img`
  aspect-ratio: 11.11;
  object-fit: auto;
  object-position: center;
  width: 574px;
  margin-top: 13px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ComingSoonDescription = styled.p`
  color: #fff;
  font-family: Josefin Sans, sans-serif;
  margin-top: 19px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutUsSection = styled.section`
  flex-basis: calc(50% - 10px); // Adjust for gap
  display: flex;
  flex-direction: column;
  line-height: normal;
  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const AboutUsContent = styled.div`
  border-radius: 10px;
  box-shadow: 4px 4px 7px 0 rgba(0, 0, 0, 0.25);
  background-color: #ffd061;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  font-weight: 600;
  text-align: center;
  justify-content: center;
  width: 100%;
  padding: 80px 60px;
  @media (max-width: 991px) {
    max-width: 100%;
    margin-top: 23px;
    padding: 80px 20px;
  }
`;

const AboutUsTitle = styled.h2`
  color: #000;
  font: 24px Josefin Sans, sans-serif;
`;

const AboutUsImage = styled.img`
  aspect-ratio: 8.33;
  object-fit: auto;
  object-position: center;
  width: 100%;
  margin-top: 18px;
  max-width: 500px; 
  max-height: 300px; 
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AboutUsButton = styled.div`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0 rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  margin-top: 32px;
  color: #fff;
  padding: 13px 45px;
  font: 20px Inter, sans-serif;
  @media (max-width: 991px) {
    padding: 13px 20px 13px 22px;
  }
`;

const BottomImage = styled.img`
  aspect-ratio: 1.28;
  object-fit: auto;
  object-position: center;
  width: 45px;
  margin: 37px auto; // Vertically maintains 37px and auto centers horizontally
  display: block; // Ensure it's treated as a block-level element for margin auto to work
`;


export default LandingPage;

