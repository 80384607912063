import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Assets from "../../assets/Assets";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";

const StyledBtn = styled.div`
  .sc-button {
    padding: 10px 20px;
    padding-right: 15px;
    display: flex;
    align-items: center;

    background-color: transparent;
  }
  img {
    width: 40px;
    margin-right: 5px;
  }
  .wallet-icon {
    width: 29px;
  }
  @media (max-width: 768px) {
    width: 100%;
    #site-header {
      display: flex;
      justify-content: center;
    }
  }
`;

const RequestDemoBtn = () => {
  const user = useSelector((state) => state.user);
  const { customNavigate } = useCustomNavigator();
  return (
    <StyledBtn>
      <div className="mr-3" id="site-header">
        <button
          className="sc-button header-slider fl-button pri-1"
          onClick={() => customNavigate({ path: "/contact-us" })}
        >
          <>
            <span>Request Demo</span>
          </>
        </button>
      </div>
    </StyledBtn>
  );
};

export default RequestDemoBtn;
