import React, { useState, useEffect } from "react";
    import { useNavigate, } from "react-router-dom";
    import styled from "styled-components";
    import { useAuth0 } from "../../auth/Auth";
    import Assets from "../../assets/Assets"
    import Footer_LP from "../../components/footer/Footer_LP";
    import LoginModal from "./loginModal";
    
    const demoAnimationData = [
      {
        id: 1,
        title: "Demo Animation 1",
        description: "Description of demo animation 1",
        image: "path/to/image1.jpg",
      },
    ];
       
    const SynthHome = () => {
      const [email, setEmail] = React.useState("");
      const [isModalOpen, setIsModalOpen] = React.useState(false);
      const { isAuthenticated, login, user } = useAuth0();
    
      const navigate = useNavigate();

      const HandleDemoClick = () => {
          if (isAuthenticated) {
              navigate('/synthprojects');
          }
        setIsModalOpen(true);
      };

      const HandleMPClick = () => {
        window.open('https://marketplace.acmelabs.xyz', '_blank');
    };

      const handleCloseModal = () => {
        setIsModalOpen(false);
      };
    
      const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
    
      const handleEmailSubmit = (event) => {
        event.preventDefault();
        console.log(`Submitting email: ${email}`);
      };
    
      return (
        <MainContainer>
          <HeroSection>
            <BackgroundVideo autoPlay loop muted>
              <source src="https://alsynthprod.s3.amazonaws.com/Landing+Page+vid_v1_HQ.mp4" type="video/mp4" />
              Your browser does not support the video tag.
            </BackgroundVideo>
            <HeroContent>
              <HeroHeader>
                <HeroTitle>Welcome to Acme Synthesizer</HeroTitle>
                <MPButton onClick={HandleMPClick}>Marketplace</MPButton>
                <DemoButton onClick={HandleDemoClick}>Beta Access</DemoButton>
              </HeroHeader>
              <HeroDescription>
                Bring your ideas to life with this AI-powered animation generator.{" "}
              </HeroDescription>
            </HeroContent>
            <DemoAnimationTitle>Loading...</DemoAnimationTitle>
          </HeroSection>
          <Footer_LP />
          <LoginModal isOpen={isModalOpen} onClose={handleCloseModal} />
        </MainContainer>
      );
    };
    
    const MainContainer = styled.main`
      background-color: #fff;
      display: flex;
      flex-direction: column;
    `;
    
    const HeroSection = styled.section`
    position: relative;  
    background-color: #d9d9d9;
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0 0px 80px;
      @media (max-width: 991px) {
        max-width: 100%;
      }
    `;
    
    const BackgroundVideo = styled.video`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; // Cover the entire section
    `;

    const HeroContent = styled.div`
    background: linear-gradient(
      180deg,
      #fff 10%,
      rgba(255, 249, 249, 0) 75%
    );
      position: relative;
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 44px 70px 80px;
      @media (max-width: 991px) {
        max-width: 100%;
        padding: 0 20px;
      }
    `;
    
    const HeroHeader = styled.header`
      display: flex;
      gap: 20px;
      @media (max-width: 991px) {
        max-width: 100%;
        flex-wrap: wrap;
      }
    `;
    
    const HeroTitle = styled.h1`
      color: #2159a2;
      flex-grow: 1;
      flex-basis: auto;
      font: 700 40px Inter, sans-serif;
      @media (max-width: 991px) {
        max-width: 100%;
      }
    `;
    
    const DemoButton = styled.button`
      justify-content: center;
      border-radius: 15px;
      box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
      background-color: #2159a2;
      color: #fbfdff;
      white-space: nowrap;
      text-align: center;
      padding: 13px 45px;
      font: 600 16px Inter, sans-serif;
      cursor: pointer;
      @media (max-width: 991px) {
        white-space: initial;
        padding: 5px 20px;
      }
    `;

    const MPButton = styled.button`
      justify-content: center;
      border-radius: 15px;
      box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
      background-color: #2159a2;
      color: #fbfdff;
      white-space: nowrap;
      text-align: center;
      padding: 13px 45px;
      font: 600 16px Inter, sans-serif;
      cursor: pointer;
      @media (max-width: 991px) {
        white-space: initial;
        padding: 5px 20px;
      }
    `;
    
    const HeroDescription = styled.p`
      color: #000;
      margin: 7px 0 25px;
      font: 400 20px Inter, sans-serif;
      @media (max-width: 991px) {
        max-width: 100%;
      }
    `;
    
    const DemoAnimationTitle = styled.h2`
      color: #000;
      align-self: center;
      margin: 221px 0 250px;
      font: 400 20px Inter, sans-serif;
      @media (max-width: 991px) {
        margin-bottom: 40px; 
      }
    `;
    
    export default SynthHome;