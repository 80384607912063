import * as React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styled from "styled-components";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import {
  ERROR_MISSING_TEXT,
  ERROR_TEXT_IN_BOTH,
} from "../../constants/constants";
import { createCharacter } from "../../api/synth/getCharacters";
import { createClaude } from "../../api/synth/claudeTestCreate"
import Spinner from "../../components/layouts/Spinner/Spinner";

const storyData = [
  {
    title: "Already have a character in mind?",
    buttonText: "Upload Your Character Description",
    description: (
      <>
        {/*<span style={{ fontWeight: 700 }}>OR</span>*/}
        <br />
        Paste your character description here
      </>
    ),
  },
  {
    title: "Starting from scratch?",
    subtitle: "AI can help you!",
    description: "Describe your chracter for your story:",
  },
];

const CreateStory = () => {
  const location = useLocation();
  const [characterName, setCharacterName] = React.useState("");
  const [characterDescription, setCharacterDescription] = React.useState("");
  const [ideaText, setIdeaText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const queryParams = new URLSearchParams(location.search);

  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');
  
  const createRequestBody = (title, scriptText) => {
    const requestBody = {
      storyId: storyId, 
      charName: characterName, 
      charDesc: characterDescription
    };
    return requestBody;
  };

  const createAiRequestBody = (ideaText) => {
    const requestBody = {
      prompt: ideaText,
    };
    return requestBody;
  }
  
  const navigate = useNavigate();

  const handleCharacterNameChange = (event) => {
    setCharacterName(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setCharacterDescription(event.target.value);
  };

  const handleIdeaChange = (event) => {
    setIdeaText(event.target.value);
  };

  const handleUploadScript = () => {
    // Handle script upload logic here
    console.log("Upload script button clicked");
  };

  const handleCancel = () => {
    navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  };

  const handleCreateCharacter = async () => {
    setIsLoading(true);
    try {
        console.log("Create character button clicked");

        if (!ideaText && !characterDescription) {
            console.log("No Data Provided");
            notifyError(ERROR_MISSING_TEXT);
            setIsLoading(false);
            return; // Stop execution if no data is provided
        }

        if (ideaText && characterDescription) {
            console.log("Both text fields are populated");
            notifyError(ERROR_TEXT_IN_BOTH);
            setIsLoading(false);
            return; // Stop execution if both fields are populated
        }

        /*if (ideaText) {
          //console.log("AI Process started");
          const requestBody = createAiRequestBody(ideaText);
          const aiResponse = await createClaude({requestBody});
          //console.log("AI Prompt:", requestBody);
          const scriptTextFromAI = `"${aiResponse}"`;
          //console.log("AI Response:", scriptTextFromAI);
          const newRequestBody = createRequestBody(projectName, scriptTextFromAI);
          //console.log("newRequestBody:", newRequestBody);
          const response = await createStories({requestBody: newRequestBody});
          //console.log("response:", response);
          const storyId = response.id;
          //console.log("storyId:", storyId);
          navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
          return;
        }*/

        if (characterDescription) {
          const requestBody = createRequestBody(storyId, characterName, characterDescription);
          const response = await createCharacter({requestBody});
          const charId = response.id;
          navigate(`/editchardetail?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&charId=${encodeURIComponent(charId)}`);
          return;
        }
    } catch (error) {
        console.error(error);
    } finally {
      setIsLoading(false);
  }
};

  return (
    <Container>
      <Title>Create A New Character</Title>
      <Subtitle>Character Name:</Subtitle>
      <ProjectNameInput
        value={characterName}
        onChange={handleCharacterNameChange}
        placeholder="Example Character Name"
      />
      <Divider />
      {isLoading && (
          <ProgressOverlay>
            <ProgressStatus>Creating Character...  This will take a moment.
            </ProgressStatus>
            <Spinner />
          </ProgressOverlay>
        )}
      <ContentWrapper>
        <StoryOption>
          <OptionTitle>{storyData[0].title}</OptionTitle>
          {/*<OptionButton*/}
           {/*onClick={handleUploadScript}*/}
          {/*>{storyData[0].buttonText}*/}
          {/*</OptionButton>*/}
          <OptionDescription>{storyData[0].description}</OptionDescription>
          <OptionExample>
            <OptionTextarea
              value={characterDescription}
              onChange={handleDescriptionChange}
              //placeholder={storyData[0].example}
              placeholder="Example Text"
            />
          </OptionExample>
        </StoryOption>
        <VerticalDivider />
        <StoryOption>
          <OptionTitle>{storyData[1].title}</OptionTitle>
          <OptionSubtitle>{storyData[1].subtitle}</OptionSubtitle>
          <OptionDescription>{storyData[1].description}</OptionDescription>
          <OptionExample>
            <OptionTextarea
              value={ideaText}
              onChange={handleIdeaChange}
              //placeholder={storyData[1].example}
              placeholder="Enter your story here"
            />
          </OptionExample>
        </StoryOption>
      </ContentWrapper>
      <HorizontalDivider />
      <ButtonWrapper>
        <CancelButton onClick={handleCancel}>Cancel</CancelButton>
        <StartButton onClick={handleCreateCharacter}>Create Character</StartButton>
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 24px;
  font-weight: 400;
`;

const Title = styled.h1`
  color: #2159a2;
  text-align: center;
  font: 900 64px Inter, sans-serif;
  @media (max-width: 991px) {
    font-size: 40px;
  }
`;

const Subtitle = styled.h2`
  color: #011126;
  text-align: center;
  margin-top: 16px;
  font: 32px Inter, sans-serif;
`;

const ProjectNameInput = styled.input`
  font-family: Inter, sans-serif;
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 25px;
  font-size: 24px;
  width: 990px;
  max-width: 100%;
  color: #2159a2;
  text-align: center;
  padding: 17px 55px;
  font-weight: bold;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const Divider = styled.div`
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  width: 100%;
  margin-top: 55px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1222px;
  gap: 20px;
  color: #011126;
  justify-content: space-between;
  padding: 0 20px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const StoryOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 15px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const OptionTitle = styled.h3`
  font: 700 32px Inter, sans-serif;
  min-width: 70%;
`;

const OptionSubtitle = styled.h4`
  color: #24777d;
  font-family: Inter, sans-serif;
  font-style: italic;
  font-weight: 600;
  margin-top: 12px;
`;

const OptionButton = styled.button`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  margin-top: 20px;
  color: #f7f5f5;
  font-weight: 600;
  padding: 15px 45px;
  @media (max-width: 991px) {
    padding: 0 20px;

  &:disabled {
    background-color: #ccc;
    color: #666;
    cursor: not-allowed;
  }
`;

const OptionDescription = styled.p`
  font-family: Inter, sans-serif;
  margin-top: 31px;
`;

const OptionExample = styled.div`
  border: 1px solid rgba(129, 129, 138, 1);
  background-color: #fff;
  margin-top: 27px;
  color: #81818a;
  padding: 26px 44px;
  font: italic 15px Inter, sans-serif;
  min-width: 100%; // Minimum width
  min-height: 400px; // Minimum height
  @media (max-width: 991px) {
    padding: 0 25px 0 20px;
  }
`;

const OptionTextarea = styled.textarea`
  width: 100%;
  height: 100%;
  border: none;
  resize: none;
  background-color: transparent;
  font: italic 15px Inter, sans-serif;
  color: #81818a;
  &::placeholder {
    color: #81818a;
  }
`;

const VerticalDivider = styled.div`
  border-left: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  width: 1px;
  height: 675px;
`;

const HorizontalDivider = styled.div`
  border-top: 1px solid rgba(217, 217, 217, 1);
  background-color: #d9d9d9;
  width: 100%;
  margin-top: 10px;
  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 45px;
  gap: 20px;
  font-weight: 600;
  text-align: center;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    margin-top: 40px;
  }
`;

const CancelButton = styled.button`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(217, 217, 217, 1);
  background-color: #fff;
  color: #2159a2;
  white-space: nowrap;
  padding: 15px 45px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const StartButton = styled.button`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #2159a2;
  color: #f7f5f5;
  flex-grow: 1;
  width: fit-content;
  padding: 15px 45px;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

export default CreateStory;