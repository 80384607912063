import * as React from "react";
import styled from "styled-components";


const SideBar = () => {
    return (
        <SidebarContent>
            <UserInfo>
              <UserDetails>
                <UserAvatar />
                <Username>Jon Smith</Username>
              </UserDetails>
              <SettingsIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/9f4da968377af5bd1d41492bd3003ee1cf0cc89b9f4ffde48b16ae2ec87a42f4?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Settings Icon" />
            </UserInfo>
            <NavItem active>
              <NavIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/b116897c76a2638a5fe16d4da4245fa4bab73f29be83643d1c5a0af8367cfff7?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Recents Icon" />
              <NavText>Recents</NavText>
            </NavItem>
            <NavItem>
              <NavIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/60209d339e815506f371a5cb82c458e634545ae3ab0ded653cd10ba527cc6adb?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Favorites Icon" />
              <NavText>Favorites</NavText>
            </NavItem>
          </SidebarContent>
)};

const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 80px;
  background-color: #f5f5f7;
  font-size: 14px;
  text-align: center;
  white-space: nowrap;
`;

const UserInfo = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 28px 22px;
  border: 1px solid #d9d9d9;
  background-color: #fbfdff;
  color: #011126;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.1px;
  line-height: 100%;
`;

const UserDetails = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const UserAvatar = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 50%;
  background-color: #d9d9d9;
`;

const Username = styled.span`
  font-family: Inter, sans-serif;
`;

const SettingsIcon = styled.img`
  width: 15px;
  aspect-ratio: 1.67;
  object-fit: contain;
`;

const NavItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: ${(props) => (props.active ? "12px 21px" : "0 21px")};
  margin: ${(props) => (props.active ? "0" : "13px 0 0 0")};
  background-color: ${(props) => (props.active ? "#2159a2" : "transparent")};
  color: ${(props) => (props.active ? "#fff" : "#000")};
  font-weight: ${(props) => (props.active ? "700" : "400")};
`;

const NavIcon = styled.img`
  width: ${(props) => (props.active ? "22px" : "25px")};
  aspect-ratio: ${(props) => (props.active ? "1" : "1.04")};
  object-fit: contain;
  align-self: ${(props) => (props.active ? "flex-start" : "center")};
`;

const NavText = styled.span`
  font-family: Inter, sans-serif;
  margin: auto 0;
`;


export default SideBar;