import React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import EditStory from "../../pages/Synth/editStory";
import EditStoryContext from "../../pages/Synth/EditStoryContext"

const SynthHeader = ({ project } ) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { saveScriptChanges } = React.useContext(EditStoryContext);

  const handleIconClick = () => {
    navigate(`/synthprojects`);
  };

  const handleStartOverClick = () => {
    navigate(`/createstory`);
  };

  const handleSaveClick = async () => {
    const currentPage = location.pathname.split("/").pop();
    // Debug
    //console.log("Current page:", currentPage);
    if (currentPage === 'editstory') {
      //${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}
      await saveScriptChanges();;
      console.log(`Save on editstory page`);
  };
}

  return (
    <Container>
      <Header>
        <ProjectName>{project}</ProjectName>
        <Actions>
          <IconButton onClick={handleIconClick}>
            <Icon src="https://cdn.builder.io/api/v1/image/assets/TEMP/f121d16a94937028b752d7ca07662a6586d259954d727e24ad33a23fd03e62fd?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Icon" />
          </IconButton>
          <StartOverButton onClick={handleStartOverClick}>
            Start Over
          </StartOverButton>
          <SaveButton onClick={handleSaveClick}>Save</SaveButton>
          <HistoryButton>
            <Icon src="https://acmelabs-temp-storage.s3.amazonaws.com/history.png"></Icon>
          </HistoryButton>
        </Actions>
      </Header>
      <Divider />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  min-height: 35px;
  width: 90%;
  margin-top: 30px;
  margin-left: 5%;
  gap: 20px;
  padding: 1px 80px 1px 0;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;
const Divider = styled.div`
  border-color: #D9D9D9;
  border-style: solid;
  border-width: 1px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProjectName = styled.h1`
  position: relative;
  color: #2159a2;
  flex-grow: 1;
  flex-basis: auto;
  width: 50%;
  font: 700 32px Inter, sans-serif;
`;

const Actions = styled.div`
  position: relative;
  display: flex;
  gap: 13px;
`;

const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #fff;
  padding: 10px 16px;
  border: none;
  cursor: pointer;
`;

const Icon = styled.img`
  aspect-ratio: 1;
  object-fit: contain;
  width: 29px;
`;

const Button = styled.button`
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  text-align: center;
  padding: 10px 30px;
  font: 600 24px Inter, sans-serif;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const StartOverButton = styled(Button)`
  background-color: #fff;
  color: #2159a2;
`;

const SaveButton = styled(Button)`
  background-color: #2159a2;
  color: #fbfdff;
  white-space: nowrap;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const HistoryButton = styled(Button)`
  background-color: #fff;
  color: #2159a2;
}
`;

export default SynthHeader;