import wrabbitHiResImg from "../images/slider/WrabbitHiRes.jpg";
import aiAcmeRobotImg from "../images/slider/ai_acme_robot_1.png";
import acmeDestructo from "../images/slider/AcmeDestructo.png";
import atomicaExoticaImg from "../images/slider/AtomicaExoticaLogo.jpg";

const heroSliderData = [
  {
    title_1: `Chuck Jones' Wrabbit™`,
    title_2:
      "Out of his crate, out of the lab, out for some fun.  The Saturday Morning Cartoon series is available now.",
    img: wrabbitHiResImg,
    button: {
      name: "Shop the Wrabbit™ Collection",
      class: "",
      path: "https://www.acmelabs.xyz/collection/638e976ceb0ac6554af2410d",
      externalSite: true,
      icon: "note",
    },
  },
  {
    title_1: `Welcome to ACME LABS™ Beta`,
    title_2: `Join our community, help us build the entertainment platform of the future`,
    img: aiAcmeRobotImg,
    button: {
      name: "Provide Feedback",
      class: "orange",
      path: "https://cms.acmelabs.xyz/feedback/?utm_source=acmelabs&utm_medium=hpslider",
      externalSite: true,
      icon: "note",
    },
  },
  {
    title_1: `The Masterpieces`,
    title_2: `Backgrounds from animation classics. Available as Fine Art Prints or Digital Art.  More art coming soon.  Only available on ACME LABS™.`,
    img: acmeDestructo,
    button: {
      name: "Shop the Masterpieces Collection",
      class: "orange",
      path: "https://www.acmelabs.xyz/collection/63859613fa4c14e64c3a238e",
      externalSite: true,
      icon: "note",
    },
  },
  // {
  //   title_1: `ATOMICA EXOTICA`,
  //   title_2: `These freaky tikis from a post-apocalyptic island are coming soon!`,
  //   img: atomicaExoticaImg,
  //   button: {
  //     name: "Register to be Notified",
  //     class: "orange",
  //     path: "https://cms.acmelabs.xyz/atomica-exotica/?utm_source=acmelabs&utm_medium=hpslider",
  //     externalSite: true,
  //     icon: "note",
  //   },
  // },
];

export default heroSliderData;
