import React, { useRef, useEffect, memo } from "react";
import { useAuth0 } from "../../auth/Auth";
import LogoHeader from "./LogoHeader";
import Navbar_LP from "./Navbar/Navbar_LP";
import MenuProfile from "./MenuProfile";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  .logo-light {
    width: 17%;
    height: auto;
  }

  @media (max-width: 768px) {
    .logo-light {
      width: 150px; // Adjust size as needed for smaller screens
      height: auto;
    }

    // Make the header layout more suitable for mobile
    #header_main {
      flex-direction: column;
      align-items: center;
    }

    .themesflat-container {
      width: 100%; // Ensure the container takes full width on mobile
    }

    // Add any additional mobile-specific styles here
  }
`;


const Header = () => {
  const { isAuthenticated } = useAuth0();
  const headerRef = useRef(null);
  
  useEffect(() => {
    const isSticky = (e) => {
      const header = document.querySelector(".js-header");
      if (header) {
        const scrollTop = window.scrollY;
        scrollTop >= 300
          ? header.classList.add("is-fixed")
          : header.classList.remove("is-fixed");
        scrollTop >= 400
          ? header.classList.add("is-small")
          : header.classList.remove("is-small");
      }
    };

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  return (
    <HeaderWrapper>
      <header id="header_main" className="header_LP js-header" ref={headerRef}>
        <div className="themesflat-container">
                <LogoHeader />
                <Navbar_LP />
        </div>
      </header>
    </HeaderWrapper>
  );
};

export default memo(Header);
