import * as React from "react";
import styled from "styled-components";
import Assets from "../../assets/Assets"
import Newsletter from "./Newsletter";
import { notify } from "../../helpers/toastNotificationService";
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import { SUCCESFUL_SUBSCRIPTION } from "../../constants/constants";
import { useApi } from "../../hooks/useApi";

const { REACT_APP_API_URL } = process.env;


const socialMediaData = [
    {
      id: 1,
      name: "Facebook",
      url: "https://www.facebook.com/Acmelabs_tm-101961962625470",
      //icon: "path/to/facebook-icon.svg",
      image: Assets.facebookIcon,
    },
    {
      id: 2,
      name: "Twitter",
      url: "https://twitter.com/acmelabs_tm",
      //icon: "path/to/twitter-icon.svg",
      image: Assets.twitterIcon,
    },
    {
      id: 3,
      name: "Instagram",
      url: "https://www.instagram.com/acmelabs_tm/",
      //icon: "path/to/instagram-icon.svg",
      image: Assets.instagramIcon,
    },
    {
      id: 4,
      name: "LinkedIn",
      url: "https://www.linkedin.com/company/acme-labs%E2%84%A2/about/",
      //icon: "path/to/linkedin-icon.svg",
      image: Assets.linkedinIcon,
    },
  ];

  const Footer_LP = () => {
    const [email, setEmail] = React.useState("");
    const { executeApiRequest } = useApi();

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
      };
    
      const handleEmailSubmit = async (event) => {
        event.preventDefault();
        console.log(`Submitting email: ${email}`);
        
        try {
          await executeApiRequest(
            `${REACT_APP_API_URL}general/email-for-marketing`,
            {
              method: "POST",
              body: JSON.stringify({
                email: email,
              }),
              headers: { "Content-Type": "application/json" },
            }
          );
          notify(SUCCESFUL_SUBSCRIPTION);
        } catch (e) {
            //console.log(e);
        }
      };
    
    return (
        <div>
         <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={true}
        /> 
        <FooterSection>
            <FooterContent>
              <AboutSection>
                <CompanyLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/7a7484c648c24394d2b3889f3a2991283102be9d4431191aa3367e79c1a19c85?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Acme Labs Logo" />
                <AboutDescription>
                ACME LABS™ is leading the innovation of creative development using ethical AI and emerging media technologies including Generative AI, Augmented Reality, and Virtual Reality.{" "}
                </AboutDescription>
              </AboutSection>
              <SocialMediaIcons>
                {socialMediaData.map((item) => (
                  <SocialMediaLink
                    key={item.id}
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <SocialMediaIcon src={item.image} alt={item.name} />
                  </SocialMediaLink>
                ))}
              </SocialMediaIcons>
            </FooterContent>
            <NewsletterSection>
              <NewsletterTitle>Sign up for our newsletter</NewsletterTitle>
              <NewsletterForm onSubmit={handleEmailSubmit}>
                <EmailInput
                  type="email"
                  id="emailInput"
                  placeholder="example@mail.com"
                  aria-label="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                  style={{ backgroundColor: 'white' }}
                  required
                />
                <EmailSubmitButton type="submit">Submit</EmailSubmitButton>
              </NewsletterForm>
            </NewsletterSection>
            <CopyrightSection>
              <CopyrightText>Copyright 2024</CopyrightText>
              <CopyrightLogo src="https://cdn.builder.io/api/v1/image/assets/TEMP/7d450af832f2863c59e24876703cb3af983aef7c280769bb0ff742be3503e6e6?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Acme Labs Copyright Logo" />
            </CopyrightSection>
          </FooterSection>
          </div>
      );
    }         

    const FooterSection = styled.footer`
    background-color: #2159a2;
    display: flex;
    width: 100%;
    flex-direction: column;
    padding: 40px 0 0 70px;
    @media (max-width: 991px) {
      max-width: 100%;
      padding-left: 20px;
      padding-top: 20px;
    }
  `;
  
  const FooterContent = styled.div`
    display: flex;
    width: 100%;
    gap: 20px;
    justify-content: space-between;
    @media (max-width: 991px) {
      max-width: 100%;
      flex-wrap: wrap;
      padding-right: 20px;
    }
  `;
  
  const AboutSection = styled.section`
    display: flex;
    flex-direction: column;
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const CompanyLogo = styled.img`
    aspect-ratio: 1.14;
    object-fit: auto;
    object-position: center;
    width: 60px;
  `;
  
  const AboutDescription = styled.p`
    font-family: Inter, sans-serif;
    margin-top: 24px;
    max-width: 800px;
    color: #FFFFFF;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const SocialMediaIcons = styled.div`
    align-self: start;
    display: flex;
    gap: 25px;
    justify-content: space-between;
    margin-right: 5%;
  `;
  
  const SocialMediaLink = styled.a`
    background-color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
  `;
  
  const SocialMediaIcon = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #2159a2;
  `;
  
  const NewsletterSection = styled.section`
    color: #fff;
    margin-top: 39px;
    font: 400 20px Inter, sans-serif;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;
  
  const NewsletterTitle = styled.h3`
    margin-bottom: 18px;
  `;
  
  const NewsletterForm = styled.form`
    display: flex;
    gap: 10px;
  `;
  
  const EmailInput = styled.input`
    background-color: #fff;
    min-width: 250px;
    max-width: 30%;
    align-items: start;
    color: #fff;
    white-space: nowrap;
    justify-content: center;
    padding: 15px 23px;
    font: 400 16px Inter, sans-serif;
    @media (max-width: 991px) {
      white-space: initial;
      padding: 0 20px;
    }
  `;
  
  const EmailSubmitButton = styled.button`
    background-color: #000;
    color: #fff;
    padding: 15px 23px;
    font: 400 16px Inter, sans-serif;
    cursor: pointer;
  `;
  
  const CopyrightSection = styled.section`
    display: flex;
    margin-top: 40px;
    gap: 20px;
    font-size: 20px;
    color: #fbfdff;
    font-weight: 400;
    @media (max-width: 991px) {
      max-width: 100%;
      flex-wrap: wrap;
      margin-top: 40px;
    }
  `;
  
  const CopyrightText = styled.p`
    font-family: Inter, sans-serif;
    flex-grow: 1;
    margin: auto 0;
    color: #FBFDFF;
  `;
  
  const CopyrightLogo = styled.img`
    aspect-ratio: 8.33;
    object-fit: auto;
    object-position: center;
    width: fit-content;
    flex-grow: 1;
    flex-basis: 0;
    @media (max-width: 991px) {
      max-width: 100%;
    }
  `;

  export default Footer_LP;