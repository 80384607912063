import React, { useEffect } from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import heroSliderData from "../assets/fake-data/data-slider";
import FeaturedPlaces from "../components/layouts/FeaturedPlaces/FeaturedPlaces";
import Slider from "../components/slider/Slider";
import TodayPicks from "../components/layouts/TodayPicks";
import PopularCollection from "../components/collection/PopularCollection/PopularCollection";
import Create from "../components/layouts/Create/Create";
import TopArtists from "../components/layouts/TopArtists";
import { signin } from "../store/slices/user/user";
import { useDispatch, useSelector } from "react-redux";
import { useAuth0 } from "../auth/Auth";
import { useApi } from "../hooks/useApi";
import "react-toastify/dist/ReactToastify.css";
import TopTrending from "../components/layouts/TopTrending/TopTrending";
import styled from "styled-components";

const { REACT_APP_API_URL } = process.env;
const StyledHome = styled.div`
  position: "relative";
  #home-overlay.active {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    right: 0;
    bottom: 0;
    background: rgba(205, 205, 205, 0.5);
    z-index: 7777;
    position: fixed;
  }

  .header_1 {
    position: absolute;
    background: var(--header-background);
    z-index: 7777;
    height: 80px;
    width: 100%;
    border-bottom: 1px solid rgba(235, 235, 235, 0.2);
  }
  .header_1.is-fixed {
    position: fixed;
    top: 0;
  }
  .header_1.active {
    background: rgba(205, 205, 205, 0.5);
  }
`;

const Home01 = () => {
  const dispatch = useDispatch();
  const { email } = useSelector((state) => state.user);
  const { user } = useAuth0();
  const { executeApiRequestTwo } = useApi();

  const executeLogin = async () => {
    const url = `${REACT_APP_API_URL}user/my`;
    const options = { method: "GET" };
    const userData = await executeApiRequestTwo(url, options);
    dispatch(signin({ ...userData, ...user }));
  };

  useEffect(() => {
    if (user && !email) {
      console.log("Logging", user);
      executeLogin();
    }
  }, [user]);

  return (
    <StyledHome className="home-1">
      <div id="home-overlay"></div>
      <Header />
      <Slider data={heroSliderData} />
      {/* <TodayPicks /> */}
      {/* <TopArtists /> */}
      {/* <PopularCollection /> */}
      {/* <TopTrending /> */}
      {/* <Create /> */}
      <Footer />
    </StyledHome>
  );
};

export default Home01;
