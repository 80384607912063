import React, { useRef, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { notify, notifyError } from "../../helpers/toastNotificationService"
import SynthFooter from "../../components/synth/Footer";
import SynthHeader from "../../components/synth/Header";
import { getCharactersById, getCharacters, createCharacter } from "../../api/synth/getCharacters";
import { updateStories } from "../../api/synth/updatestories";
import Spinner from "../../components/layouts/Spinner/Spinner";
import ImportModal from "../../components/synth/ImportModal";

const sections = [
  {
    title: "Style",
    options: ["Option 1", "Option 2", "Option 3"],
  },
  {
    title: "Color Scheme",
    options: ["Option 1", "Option 2", "Option 3"],
  },
  {
    title: "Try Something Crazy",
    options: ["Option 1", "Option 2", "Option 3"],
  },
];

const EditChar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [isLoading, setLoading] = useState(false);
    const [characters, setCharacters] = React.useState([]);
    const [showModal, setShowModal] = useState(false);


  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');


    // Get All Characters for this Story
  React.useEffect(() => {
    const fetchCharacters = async () => {
      setLoading(true);
      try {
        const response = await getCharacters(storyId);
        console.log('Characters response:', response);
        if (Array.isArray(response) && response.length > 0) {
          setCharacters(response);
          setShowModal(false); // Hide modal if characters are found
        } else {
          //console.error('No characters found or not an array:', response);
          setCharacters([]);
          setShowModal(true); // Show modal if no characters are found
        }
      } catch (error) {
        console.error('Error fetching characters:', error);
        setShowModal(true); // Show modal on error
      } finally {
        setLoading(false);
      }
    };  
  
    fetchCharacters();
  }, [storyId]); // Dependency array to refetch if storyId changes
  
 
  const handleCharacterClick = (character) => {
    //console.log(`Clicked on character: ${character.name, character.id}`);
    navigate(`/editchardetail?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}&charId=${encodeURIComponent(character.id)}`);
  };

  const handleAddCharacterClick = async () => {
    console.log("Clicked on Add Character button");
    navigate(`/createchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  };

  const handleCharacterImport = () => {
    console.log("Importing characters");
    setShowModal(false);
  }

  const handleReturnToStory = () => {
    navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
  }
  
  const handleIconClick = (character, iconIndex) => {
    console.log(
      `Clicked on icon ${iconIndex + 1} for character: ${character.name}`
    );
  };

    const [selectedOptions, setSelectedOptions] = React.useState(
    sections.map(() => null)
  );

  const handleOptionChange = (sectionIndex, optionIndex) => {
    setSelectedOptions((prevSelectedOptions) => {
      const newSelectedOptions = [...prevSelectedOptions];
      newSelectedOptions[sectionIndex] = optionIndex;
      return newSelectedOptions;
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const userInput = event.target.elements.userInput.value;
    console.log("User input:", userInput);
  };

  const handleCancelModal = () => {
    setShowModal(false);
  };
  

  return (
    <AppWrapper>
      <SynthHeader project={projectName} />
      <PageBody>
        <Main>
          <CharacterDesignContainer>
            <Title>Character Design</Title>
            <CharacterGrid>
              <CharacterList>
                {characters.map((character, index) => (
                  <CharacterCard
                    key={index}
                    onClick={() => handleCharacterClick(character)}
                  >
                    <CharacterImageWrapper>
                      <CharacterImage src={character.charImage} alt={character.charName} />
                      <CharacterDetails>
                        <CharacterName>{character.charName}</CharacterName>
                        <CharacterDescription>
                          {character.charDesc}
                        </CharacterDescription>
                      </CharacterDetails>
                      {/* Assuming characters have a `icons` property */}
                      <CharacterIcons>
                      </CharacterIcons>
                    </CharacterImageWrapper>
                  </CharacterCard>
                ))}
              </CharacterList>
              <AddCharacterCard>
                <AddCharacterButton onClick={handleAddCharacterClick}>
                  <AddCharacterIcon>+</AddCharacterIcon>
                  <AddCharacterText>Add Character</AddCharacterText>
                </AddCharacterButton>
              </AddCharacterCard>
            </CharacterGrid>
          </CharacterDesignContainer>
        </Main>
    <Sidebar>
    <CharactersTitle>Characters</CharactersTitle>
      <SidebarCharacterList>
        {characters.map((character, index) => (
          <React.Fragment key={character.charName}>
            <CharacterItem>
              <SidebarCharacterImage src={character.charImage} alt={character.charName} />
              <SidebarCharacterName>{character.charName}</SidebarCharacterName>
              <EditIcon onClick={handleAddCharacterClick} src="https://cdn.builder.io/api/v1/image/assets/TEMP/101194c285a27b25d59e5673a9bda2b35da087d49c87234db1772eee4587b366?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Edit character" />
            </CharacterItem>
            {index < characters.length - 1 && <SidebarDivider />}
          </React.Fragment>
        ))}
      </SidebarCharacterList>
      <StyleTitle>Overall Character Style</StyleTitle>
      {sections.map((section) => (
        <React.Fragment key={section.title}>
          <SectionTitle>{section.title}</SectionTitle>
          <Dropdown>
            <DropdownText>{section.options[0]}</DropdownText>
            <DropdownIcon src="https://cdn.builder.io/api/v1/image/assets/TEMP/e4ac5980bb131a5b8a88af422228b1ef331b85f15ea4655b64f28f607ec47f0c?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&" alt="Dropdown arrow" />
          </Dropdown>
        </React.Fragment>
      ))}
      <AiHelpText>
        Use AI to help you with your characters.
        <br />
      </AiHelpText>
      <AiInstructionText>
        Say what you want to change in your story, and AI will implement those changes.{" "}
      </AiInstructionText>
      <form>
        <UserInput
          type="text"
          id="userInput"
          placeholder="User inputs text here"
          aria-label="User inputs text here"
        />
        <SubmitButton type="submit">Submit</SubmitButton>
      </form>
    </Sidebar>
    {showModal && (
        <ImportModal
          onReturn={handleReturnToStory}
          onImport={handleCharacterImport}
        />
      )}
    </PageBody>
    <Divider />
    <SynthFooter />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  background-color: #fbfdff;
  display: flex;
  flex-direction: column;
`;


const PageBody = styled.div`
display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;


const Main = styled.main`
align-items: flex-start;
  margin-top: 42px;
  width: 80%;
  max-width: 1329px;
  @media (max-width: 991px) {
    max-width: 80%;
    margin-top: 40px;
  }
`;


const Sidebar = styled.main`
  background-color: #f7f5f5;
  width: 400px; // Set a fixed width for the sidebar
  flex-shrink: 0; // Prevent the sidebar from shrinking
  flex-direction: column;
  font-size: 16px;
  color: #000;
  font-weight: 400;
  @media (max-width: 991px) {
    margin-top: 40px;
    padding-right: 20px;
  }
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 4px;
  background-color: #2159a2;
  z-index: 10;
  min-height: 4px;
  margin-top: 37px;
  width: 100%;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;
const CharacterDesignContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 20px 0;
  min-height: 800px;
  @media (max-width: 991px) {
    padding-top: 40px;
  }
`;

const Title = styled.h1`
  color: #011126;
  font: 700 40px Inter, sans-serif;
  text-align: center;
  margin: 0; // Remove default margin to help with centering
  display: flex;
  align-items: center; // Vertically center the text in the flex container
  justify-content: center; // Horizontally center the text in the flex container
  height: 100%; // Take full height of parent
`;

const CharacterGrid = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 44px;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 24px;
    margin-top: 40px;
  }
`;

const CharacterList = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 24px;
  }
`;

const CharacterCard = styled.button`
width: 220px;
height: 500px;
flex-shrink: 0;
background-color: #fff;
border: 1px solid #2159a2;
box-shadow: 4px 4px 6.5px 0px rgba(0, 0, 0, 0.25);
overflow: hidden; // Ensures that content doesn't overflow the card
`;

const CharacterImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-bottom: 80px;
  flex-grow: 1; // Allows the wrapper to expand to fill available space
`;

const CharacterImage = styled.img`
  width: 90%;
  aspect-ratio: 0.3;
  
  background-color: #d9d9d9;
  max-height: 40%; // Limits the image height to avoid overflow
  margin: 0 auto; // Center the image within the wrapper
`;

const CharacterDetails = styled.div`
  padding: 21px 11px 12px;
  background-color: #fbfdff;
  margin-top: -82px;
  width: 100%; // Set the width to 100% of the parent container
  box-sizing: border-box; // Ensures padding and border are included in the width calculation
  @media (max-width: 991px) {
    margin-top: -10px;
  }
`;

const CharacterName = styled.h2`
  color: #011126;
  font: 700 15px Inter, sans-serif;
`;

const CharacterDescription = styled.p`
  color: #011126;
  font: 400 15px Inter, sans-serif;
  margin-top: 10px;
`;

const CharacterIcons = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: start;
  gap: 20px;
  padding: 0 2px;
  margin-top: 48px;

  @media (max-width: 991px) {
    margin-top: 40px;
  }
`;

const CharacterIconButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`;

const CharacterIcon = styled.img`
  width: 22px;
  aspect-ratio: 1.1;
  object-fit: contain;
`;

const AddCharacterCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 220px;
  @media (max-width: 991px) {
    width: 100%;
    margin-top: 24px;
  }
`;

const AddCharacterButton = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 41px;
  background-color: #fbfdff;
  border: 1px solid rgba(217, 217, 217, 1);
  box-shadow: 4px 4px 6.5px 0px rgba(0, 0, 0, 0.25);
  cursor: pointer;

  @media (max-width: 991px) {
    padding: 40px 20px;
  }
`;

const AddCharacterIcon = styled.span`
  color: #2159a2;
  font: 96px Inter, sans-serif;
  margin-bottom: 32px;

  @media (max-width: 991px) {
    font-size: 40px;
    margin-bottom: 20px;
  }
`;

const AddCharacterText = styled.span`
  color: #011126;
  font: 30px Inter, sans-serif;
`;
const CharactersTitle = styled.h2`
  margin: 36px 0 25px 33px;
  font: 20px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const SidebarCharacterList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 21px;
  gap: 20px;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const CharacterItem = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
`;

const SidebarCharacterImage = styled.img`
  object-fit: contain;
  object-position: center;
  width: 50px;
  height:  50px;
  border-radius: 50%;
`;

const SidebarCharacterName = styled.span`
  font-family: Inter, sans-serif;
`;

const EditIcon = styled.img`
  aspect-ratio: 0.8;
  object-fit: contain;
  object-position: center;
  width: 24px;
  position: absolute;
  right: 15px;
  
`;

const SidebarDivider = styled.hr`
  border: none;
  border-top: 1px solid #e4e4e4;
  background-color: #e4e4e4;
  margin: 0 0;
`;

const StyleTitle = styled.h2`
  margin: 19px 0 9px 11px;
  font: 600 18px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const SectionTitle = styled.h3`
  margin: 40px 0 19px 11px;
  font: 600 18px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }

  &:first-of-type {
    margin-top: 0;
  }
`;

const Dropdown = styled.div`
  border: 1px solid #d9d9d9;
  background-color: #fff;
  display: flex;
  gap: 20px;
  font-size: 15px;
  justify-content: space-between;
  margin: 0 11px;
  padding: 10px 16px 10px 12px;

  @media (max-width: 991px) {
    margin: 0 10px;
  }
`;

const DropdownText = styled.span`
  font-family: Inter, sans-serif;
`;

const DropdownIcon = styled.img`
  aspect-ratio: 1.79;
  object-fit: contain;
  object-position: center;
  width: 16px;
  margin: auto 0;
`;

const AiHelpText = styled.p`
  color: #24777d;
  margin: 28px 0 13px 12px;
  font: 700 18px Inter, sans-serif;

  @media (max-width: 991px) {
    margin-left: 10px;
  }
`;

const AiInstructionText = styled.p`
  color: #011126;
  font: 14px Inter, sans-serif;
`;

const UserInput = styled.input`
  border: 1px solid #81818a;
  background-color: #fff;
  width: 334px;
  max-width: 100%;
  color: #81818a;
  margin: 15px 12px 0;
  padding: 29px 25px;
  font: 15px Inter, sans-serif;

  @media (max-width: 991px) {
    margin: 0 10px;
    padding: 29px 20px;
  }
`;

const SubmitButton = styled.button`
  font-family: Inter, sans-serif;
  justify-content: center;
  border-radius: 15px;
  box-shadow: 3px 4px 6.1px 0px rgba(0, 0, 0, 0.25);
  background-color: #24777d;
  align-self: center;
  margin-top: 20px;
  color: #fbfdff;
  font-weight: 600;
  white-space: nowrap;
  text-align: center;
  padding: 10px 45px;
  border: none;
  cursor: pointer;

  @media (max-width: 991px) {
    white-space: initial;
    padding: 10px 20px;
  }
`;

const VisuallyHidden = styled.span`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
`;

const ProgressOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  right: 0;
  bottom: 0;
  background: rgba(205, 205, 205, 0.8);
  z-index: 7777;
  position: fixed;

  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  `;
  
  const ProgressStatus = styled.div`
    display: block;
    width: 100%;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    margin-bottom: 8px;
    text-align: center;
    color: #011126;
`;

export default EditChar;