import React from "react";
import styled from "styled-components";
import { COMMON_STYLES } from "../../../constants/genericStyles";
import useCheckMobileScreen from "../../../hooks/useCheckMobileScreen";

const StyledSliderCentered = styled.div`
  ${COMMON_STYLES.MAIN_SLIDER_BACKGROUND}
  background-color:#011126;
  display: flex;
  flex-direction: row;
  align-items: center;
  .iframe-container {
    width: 100%;
    height: 500px;
  }
  .main-content {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1440px;
  }
  .slider-centered-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
  }

  @media (max-width: 768px) {
    .iframe-container {
      width: 100%;
      height: 250px;
    }
  }
`;

const SliderIframe = (props) => {
  const isMobile = useCheckMobileScreen();

  const iframe = () => {
    return {
      __html: `'<iframe src="${props.cmsURL}" width="100%" height=${
        isMobile ? "250" : "500"
      } style="overflow-y: scroll;" scrolling="yes"></iframe>'`,
    };
  };

  return (
    <StyledSliderCentered className="flat-title-page">
      <div className="main-content">
        <div className="slider-centered-content">
          <div className="iframe-container">
            {props.cmsURL && (
              <div className="faq-content" dangerouslySetInnerHTML={iframe()} />
            )}
          </div>
        </div>
      </div>
    </StyledSliderCentered>
  );
};

export default SliderIframe;
