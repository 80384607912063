export const landingPageMenu = [
  {
    id: 1,
    name: "Products",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "ACME Synthesizer",
        links: "/synthhome",
      },
      {
        id: 2,
        sub: "ACME Atomizer",
        links: "#",
        disabled: true,
      },
      {
        id: 3,
        sub: "Marketplace",
        links: "https://acmelabs.xyz/",
        externalSite: true,
      },
    ],
  },
  {
    id: 2,
    name: "About Us",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "About",
        links: "#",
        disabled: true,
      },
      {
        id: 2,
        sub: "Team",
        links: "#",
        disabled: true,
      },
      {
        id: 3,
        sub: "Blog",
        links: "/#",
        disabled: true,
      },
    ],
  },
];

export const desktopMenus = [
  {
    id: 1,
    name: "Home",
    links: "/",
  },
  {
    id: 2,
    name: "Explore",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Art",
        links: "/explore-items",
      },
      {
        id: 2,
        sub: "Artists",
        links: "/authors-01",
      },
      {
        id: 3,
        sub: "Museum",
        links: "https://acmelabs.xyz/webxr/?lid=173&sid=chuckjones",
        externalSite: true,
        disabled: true,
      },
      {
        id: 4,
        sub: "Auctions",
        links: "#",
        disabled: true,
      },
      {
        id: 5,
        sub: "Games",
        links: "#",
        disabled: true,
      },
      {
        id: 6,
        sub: "Collectibles",
        links: "#",
        disabled: true,
      },
      {
        id: 7,
        sub: "Avatars",
        links: "#",
        disabled: true,
      },
    ],
  },
  {
    id: 3,
    name: "Engage",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Activities",
        links: "/activity-02",
      },
      {
        id: 2,
        sub: "Community",
        links: "#",
        disabled: true,
      },
      {
        id: 3,
        sub: "Blog",
        links: "/blog",
        disabled: true,
      },
    ],
  },
  {
    id: 4,
    name: "Learn",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Help",
        links: "https://cms.acmelabs.xyz/get-started-on-acme-labs/",
        externalSite: true,
      },
      {
        id: 2,
        sub: "FAQ",
        links: "/faq",
      },
      {
        id: 3,
        sub: "Contact Us",
        links: "/contact-us",
      },
    ],
  },
];

export const userMenu = (data) => [
  {
    id: 1,
    sub: "My items",
    links: "/my-items",
    icon: "fas fa-treasure-chest",
  },
  {
    id: 2,
    sub: "My Cart",
    links: "/cart",
    icon: "fas fa-shopping-cart",
  },
  {
    id: 3,
    sub: "Orders",
    links: "/my-orders",
    icon: "fas fa-truck",
  },
  /*{
    id: 4,
    sub: "Place",
    links: `/spaces/${data?.customURL}`,
    icon: "fad fa-rocket",
  },*/
  {
    id: 5,
    sub: "Profile",
    links: "/edit-profile",
    icon: "fas fa-pencil-alt",
  },
];

export const mobileMenus = [
  {
    id: 1,
    name: "Explore",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Art",
        links: "/explore-items",
      },
      {
        id: 2,
        sub: "Artists",
        links: "/authors-01",
      },
      {
        id: 3,
        sub: "Museum",
        links: "https://acmelabs.xyz/webxr/?lid=173&sid=chuckjones",
        externalSite: true,
        disabled: true,
      },
      {
        id: 4,
        sub: "Auctions",
        links: "#",
        disabled: true,
      },
      {
        id: 5,
        sub: "Games",
        links: "#",
        disabled: true,
      },
      {
        id: 6,
        sub: "Collectibles",
        links: "#",
        disabled: true,
      },
      {
        id: 7,
        sub: "Avatars",
        links: "#",
        disabled: true,
      },
    ],
  },
  {
    id: 2,
    name: "Engage",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Activities",
        links: "/activity-02",
      },
      {
        id: 2,
        sub: "Community",
        links: "#",
        disabled: true,
      },
      {
        id: 3,
        sub: "Blog",
        links: "/blog",
        disabled: true,
      },
    ],
  },
  {
    id: 3,
    name: "Learn",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "Help",
        links: "https://cms.acmelabs.xyz/get-started-on-acme-labs/",
        externalSite: true,
      },
      {
        id: 2,
        sub: "FAQ",
        links: "/faq",
      },
      {
        id: 3,
        sub: "Contact Us",
        links: "/contact-us",
      },
    ],
  },
];
