import * as React from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";


const data = [
  {
    id: 1,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d7dc714d282a8edf570d2c67822dbf94ec93038bd230b96262e0795cb3eda221?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Script",
    isActive: true,
  },
  {
    id: 2,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/d403b6955b1b50b90b0e8cec1945ea1ba5cbfa80e1b5906620185b288f6541d7?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Characters",
    isActive: true,
  },
  {
    id: 3,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/e367f0818ed75974c77dc678ce6a4ad1fc275ab8882a19384e381ad9b62b63d9?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Storyboard",
    isActive: false,
  },
  {
    id: 4,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/9da6c3e66943d6649dbf9b1b523bc086753bd247c6c37758443db1582c56490e?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Animate",
    isActive: false,
  },
  {
    id: 5,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/8dd7dc1b6bf9967e06478024f77ba4292e577c774c781d89cc37ea01834a6cf6?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Sound Design",
    isActive: false,
  },
  {
    id: 6,
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/dbd574d1606891eeb8fc89dcf13b19b3a0ec1ea295bf45e22fc23d12be81e04b?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&",
    title: "Review",
    isActive: false,
  },
];

const SynthFooter = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(1);
  const queryParams = new URLSearchParams(location.search);
  const projectName = queryParams.get('projectName');
  const storyId = queryParams.get('storyId');

  const handleStepClick = (stepId) => {
    setActiveStep(stepId);
    if (stepId == 1) {
      navigate(`/editstory?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    }
    if (stepId == 2) {
      navigate(`/editchar?projectName=${encodeURIComponent(projectName)}&storyId=${encodeURIComponent(storyId)}`);
    }
    /*if (stepId == 3) {
      navigate(`#`);
    }
    if (stepId == 4) {
      navigate(`#`);
    }
    if (stepId == 5) {
      navigate(`#`);
    
    if (stepId == 6) {
      navigate(`#`);
    }}*/

  };

  const AiProgress = "https://acmelabs-temp-storage.s3.amazonaws.com/AIProgress.jpg";
  const LeftArrow = "https://cdn.builder.io/api/v1/image/assets/TEMP/1b50020d329cf61c5719aef36d96fe6c6f5e1000118324940030d784dc1b00ac?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&";
  const RightArrow = "https://cdn.builder.io/api/v1/image/assets/TEMP/2e62b0503de2c849241c3ddf551db427a52855f6eeb2347de1aca6c43761e774?apiKey=6ef1b98222454dfe8bba8941b6ab01b5&";
  
  return (
    <Container>
      <Footer>
        <Logo src={AiProgress} alt="AI Progress" />
        <Divider />
        <Connector src={LeftArrow} alt="Connector" />
        {data.map((item) => (
          <StepButton
            key={item.id}
            isActive={item.id === activeStep}
            onClick={() => handleStepClick(item.id)}
          >
            <StepIcon src={item.icon} alt={`${item.title} Icon`} />
            <StepTitle>{item.title}</StepTitle>
          </StepButton>
        ))}
        <Connector src={RightArrow} alt="Connector" />
      </Footer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 13px;
  color: #2159a2;
  font-weight: 600;
  justify-content: center;
`;

const Footer = styled.header`
  background-color: #fbfdff;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 16px 22px 0 44px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding: 0 20px;
  }
`;

const Logo = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 79px;
  align-self: start;
  margin-top: 4px;
`;

const Divider = styled.div`
  border-color: rgba(33, 89, 162, 1);
  border-style: solid;
  border-width: 1px;
  background-color: #2159a2;
  align-self: start;
  width: 1px;
  height: 85px;
`;

const Connector = styled.img`
  color: #2159A2 ; 
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 43px;
  fill: #fbfdff;
  stroke-width: 1px;
  stroke: #2159a2;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  align-self: stretch;
  margin: auto 0;
`;

const StepButton = styled.button`
  display: flex;
  color: #2159a2;
  align-items: center;
  gap: 15px;
  white-space: nowrap;
  text-align: center;
  margin: auto 0;
  padding: 10px 20px;
  border-radius: 20px;
  box-shadow: ${(props) =>
    props.isActive ? "1px 4px 6.6px 0 rgba(0, 0, 0, 0.25)" : "4px 4px 6px 0 rgba(0, 0, 0, 0.25)"};
  border: ${(props) => (props.isActive ? "none" : "1px solid rgba(217, 217, 217, 1)")};
  background-color: ${(props) => (props.isActive ? "#ccedff" : "#fbfdff")};
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const StepIcon = styled.img`
  aspect-ratio: ${(props) => {
    switch (props.src) {
      case "{{ext_39}}":
        return "0.78";
      case "{{ext_40}}":
        return "1.04";
      case "{{ext_41}}":
        return "0.88";
      case "{{ext_42}}":
        return "1.64";
      case "{{ext_43}}":
        return "0.66";
      case "{{ext_44}}":
        return "1.08";
      default:
        return "1";
    }
  }};
  object-fit: auto;
  object-position: center;
  width: ${(props) => {
    switch (props.src) {
      case "{{ext_45}}":
        return "21px";
      case "{{ext_46}}":
        return "27px";
      case "{{ext_47}}":
        return "21px";
      case "{{ext_48}}":
        return "33px";
      case "{{ext_49}}":
        return "21px";
      case "{{ext_50}}":
        return "30px";
      default:
        return "24px";
    }
  }};
`;

const StepTitle = styled.div`
  font-family: Inter, sans-serif;
  margin: auto 0;
`;

export default SynthFooter;