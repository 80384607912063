//import { CUSTOM_PAGE_SIZE } from "../../constants/constants";
import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const createStories = async ({ requestBody }) => {
  try {
    const { headers } = await getHeaders();
    const response = await fetch(`${REACT_APP_API_URL}story/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...headers,
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (error) {
    console.log(error);
  }
  };