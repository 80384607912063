import * as React from "react";
import styled from "styled-components";

const versionHistoryData = [
  {
    date: "January 30, 2024",
    time: "3:45 PM EST",
    version: "Current Version",
  },
  {
    date: "January 30, 2024",
    time: "2:15 PM EST",
  },
  {
    date: "January 30, 2024",
    time: "1:45 PM EST",
  },
];

function VersionHistoryItem({ date, time, version, onClick }) {
  return (
    <VersionHistoryItemButton onClick={onClick}>
      <VersionHistoryDetails>
        <VersionHistoryDateTime>
          {date} {time}
        </VersionHistoryDateTime>
        {version && <VersionHistoryLabel>{version}</VersionHistoryLabel>}
      </VersionHistoryDetails>
      <VersionHistoryArrow
        src="arrow-right.svg"
        alt="Version history arrow"
      />
    </VersionHistoryItemButton>
  );
}

function VersionHistory() {
  const handleItemClick = (index) => {
    console.log(`Clicked version history item at index ${index}`);
  };

  const handleCloseClick = () => {
    console.log("Clicked close button");
  };

  const handleYesterdayClick = () => {
    console.log("Clicked yesterday button");
  };

  const handlePastWeekClick = () => {
    console.log("Clicked past week button");
  };

  return (
    <VersionHistoryContainer>
      <VersionHistoryHeader>
        <VersionHistoryTitle>
          <VersionHistoryIcon
            src="version-history-icon.svg"
            alt="Version history icon"
          />
          <VersionHistoryText>Version History</VersionHistoryText>
        </VersionHistoryTitle>
        <CloseButton onClick={handleCloseClick}>x</CloseButton>
      </VersionHistoryHeader>
      <TodayLabel>Today</TodayLabel>
      {versionHistoryData.map((item, index) => (
        <VersionHistoryItem
          key={index}
          {...item}
          onClick={() => handleItemClick(index)}
        />
      ))}
      <YesterdayButton onClick={handleYesterdayClick}>
        <YesterdayText>Yesterday</YesterdayText>
        <YesterdayArrow src="arrow-down.svg" alt="Yesterday arrow" />
      </YesterdayButton>
      <PastWeekButton onClick={handlePastWeekClick}>
        <PastWeekText>Past Week</PastWeekText>
        <PastWeekArrow src="arrow-down.svg" alt="Past week arrow" />
      </PastWeekButton>
    </VersionHistoryContainer>
  );
}

const VersionHistoryContainer = styled.div`
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  background-color: #fbfdff;
  padding: 38px 37px 80px;
  display: flex;
  flex-direction: column;
`;

const VersionHistoryHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000;
`;

const VersionHistoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 24px;
  font-weight: 700;
`;

const VersionHistoryIcon = styled.img`
  width: 42px;
`;

const VersionHistoryText = styled.span`
  font-family: Inter, sans-serif;
`;

const CloseButton = styled.button`
  font-size: 36px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  background: none;
  border: none;
  cursor: pointer;
`;

const TodayLabel = styled.h2`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: Inter, sans-serif;
  margin: 59px 0 20px;
  align-self: flex-start;
`;

const VersionHistoryItemButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 18px 27px;
  background-color: #fbfdff;
  border: 1px solid rgba(217, 217, 217, 1);
  width: 370px;
  max-width: 100%;
  align-self: center;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 11px;
  }
`;

const VersionHistoryDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const VersionHistoryDateTime = styled.time`
  color: #000;
  font-size: 16px;
  font-weight: 500;
  font-family: Inter, sans-serif;
`;

const VersionHistoryLabel = styled.span`
  color: #807f7f;
  font-size: 14px;
  font-weight: 400;
  font-family: Inter, sans-serif;
  margin-top: 11px;
`;

const VersionHistoryArrow = styled.img`
  width: 21px;
`;

const YesterdayButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 32px;
  align-self: flex-start;
  background: none;
  border: none;
  cursor: pointer;
`;

const YesterdayText = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: Inter, sans-serif;
  white-space: nowrap;
`;

const YesterdayArrow = styled.img`
  width: 14px;
  stroke: #000;
  stroke-width: 2px;
  border: 2px solid #000;
`;

const PastWeekButton = styled.button`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 29px;
  align-self: flex-start;
  background: none;
  border: none;
  cursor: pointer;
`;

const PastWeekText = styled.span`
  color: #000;
  font-size: 20px;
  font-weight: 600;
  font-family: Inter, sans-serif;
`;

const PastWeekArrow = styled.img`
  width: 14px;
  stroke: #000;
  stroke-width: 2px;
  border: 2px solid #000;
  margin-top: 7px;
`;

export default VersionHistory;