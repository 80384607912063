import { getHeaders } from "../Item/utils";
const { REACT_APP_API_URL } = process.env;

export const deleteCharacter = async (id) => {
    try {
      const { headers } = await getHeaders();
      const response = await fetch(`${REACT_APP_API_URL}story/character/delete/${id}`, {
        method: "POST",
        headers,
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };