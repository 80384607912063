import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useAuth0 } from "../../auth/Auth";
import { displayNameFormatter } from "../../helpers/formatters";
import useApi2 from "../../hooks/useApi2";
import useCheckMobileScreen from "../../hooks/useCheckMobileScreen";
import { useCustomNavigator } from "../../hooks/useCustomNavigator";
import useHashconnect from "../../hooks/useHashconnect";
import { useRole } from "../../hooks/useRole";
import useVenly from "../../hooks/useVenly";
import { userMenu } from "../../pages/menu";
import { signout } from "../../store/slices/user/user";
import LinkWithQueryParams from "../LinkWithQueryParams";
import UserImage from "./UserImage";
import styled from "styled-components";

const MenuProfile = () => {
  const isMobile = useCheckMobileScreen();
  const { customNavigate } = useCustomNavigator();
  const [visible, setVisible] = useState(false);
  //const { isAdmin } = useRole();
  //const { venlyLogout } = useVenly();
  const { disconnect } = useHashconnect();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const user = useSelector((state) => state.user);
  //const { data } = useApi2("space/my", { method: "GET" });
  // TO DO: This is not displaying the username StylePropertyMapReadOnly.
  const displayName = displayNameFormatter({ user });
  //const displayName = "Logged In User"
  const handleMenu = () => {
    setVisible(!visible);
  };


  const UserName = styled.div`
  color: #011126;
  font-size: 20px;
  font-family: Inter;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.10px;
  `;

  const handleLogout = async () => {
    localStorage.removeItem("persist:root");
    localStorage.removeItem("cart");
    await disconnect();
    dispatch(signout);
    await logout();
    customNavigate({ path: "/" });
    window.location.reload();
  };

  const arrow = !visible ? <span>&#8964;</span> : <span>&#8963;</span>;

  return (
    !isMobile && (
      <div id="header_admin" onClick={handleMenu}>
        <div className="header_avatar">
          <div className="UserName">
            <span>
              {displayName} {arrow}{" "}
              </span>
          </div>

          {/*<UserImage imgProfile={user.profileFileName} />*/}

          {visible && (
            <div className="avatar_popup mt-20">
              <div className="hr"></div>
              <div className="links mt-20">
                <Link
                  className="mt-10"
                  to="#"
                  onClick={handleLogout}
                  id="logout"
                >
                  <i className="fal fa-sign-out"></i> <span> Logout</span>
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

export default MenuProfile;
