import React from "react";
import styled from "styled-components";
import PopularCollection from "../../components/collection/PopularCollection/PopularCollection";
import Layout from "../../components/layouts/Layout";
import { HEADER_HEIGHT, MOBILE_HEADER_HEIGHT } from "../../constants/constants";

const StyledExplore = styled.div`
  #spacer {
    padding-top: ${HEADER_HEIGHT.toString() + "px"};
  }
  .filter-label {
    text-transform: capitalize;
  }
  @media (max-width: 768px) {
    #spacer {
      padding-top: ${MOBILE_HEADER_HEIGHT.toString() + "px"};
    }
    .sc-button-7 {
      padding: 12px 33px;
      border-radius: 10px;
      border: 2.5px solid #ff7b7c;

      a span {
        font-size: 14px;
        line-height: 14px;
      }
    }
  }
`;

const ExploreCollections = () => {
  return (
    <Layout>
      <StyledExplore>
        <div id="spacer"></div>
        <PopularCollection />
      </StyledExplore>
    </Layout>
  );
};

export default ExploreCollections;
